@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.sms {
  color: $color-white;
  display: block;
  background-image: url('../SocialShare/images/icon-sms-white.svg');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 14px 16px;
  background-color: $color-brand-secondary;

  @include medium {
    margin: 0 auto 30px;
  }
}

$headerHeight: 200px;

.header {
  position: fixed;
  width: 100%;
  top: -$headerHeight;
  z-index: 9999;
  transition: all 0.3s ease-in-out;
}

.header--show {
  transform: translateY($headerHeight);
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.content {
  padding: 12px;

  @include medium {
    padding: 0;
  }
}

.info-container {
  margin: 0;

  @include medium {
    margin: 0;
    padding: 20px;
  }
}

.avatar-wrapper {
  display: flex;
  margin-bottom: 12px;

  @include medium {
    margin-bottom: 0;
  }
}

.avatar {
  border-radius: 50%;
  border: 2px solid $color-white;
  float: left;
  height: 35px;
  margin-right: 10px;
  object-position: center;
  object-fit: cover;
  width: 35px;

  @include medium {
    display: none;
  }
}

.owner-name {
  margin-bottom: 0px;

  @include medium {
    margin-bottom: 8px;
  }
}

.date {
  clear: both;
  padding: 4px 0;

  @include medium {
    display: none;
    float: right;
    text-align: center;
  }
}

.message {
  margin-bottom: 5px;
  word-break: break-word;
  word-wrap: break-word;

  @include large {
    margin-bottom: 0;
  }
}

.media-container {
  margin-top: 12px;

  @include medium {
    padding: 10px 0;
  }
}

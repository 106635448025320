.update-details {
  text-align: center;

  color: #262626;
  margin: 16px auto 40px;
  padding: 0;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #ccc;
  width: calc(100% - 80px);
  position: relative;

  .update-details-content {
    padding: 22px 22px 10px;

    &:before,
    &:after {
      border: solid transparent;
      border-width: 12px;
      border-bottom-color: #ccc;
      margin-top: -12px;
      content: ' ';
      height: 0;
      pointer-events: none;
      position: absolute;
      left: calc(50% - 6px);
      top: -12px;
      width: 0;
    }

    &:after {
      border-bottom-color: #fff;
      margin-top: -10px;
    }

    .update-details-text {
      text-align: left;
      padding-bottom: 10px;
    }

    .update-details-text-bottom {
      margin: 22px 0 0;
    }

    .update-details-text-only {
      padding: 0;
    }
  }
}

.update-details-avatar {
  text-align: center;
  margin-top: 16px;
}

.update-details-owner-name {
  margin: 8px 0 0;
}

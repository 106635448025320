@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.modal {
  &,
  .modal-background {
    padding: 20px;
    background-color: #fafafa;
    text-align: center;
  }
}

.image {
  margin-bottom: 18px;
}

.message {
  margin-bottom: 32px;
}

.button {
  max-width: 360px;
  display: block;
  margin: 0 auto 18px;
}

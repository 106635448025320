@import '../../../dna-core/components/global/global';
@import '../../../dna-core/components/variables/components/form';

//++++++++++++++++++++++++++++++++
//+ DNA character-count
//++++++++++++++++++++++++++++++++

.dna-character-count-wrap{
	position:relative;
}

	.dna-input,
	.dna-textarea{
		.dna-character-count-wrap-hover & {
			border-color:@form-border-color-focused;
		}
	}

	.dna-input{
		.dna-character-count-wrap & {
			padding-right:50px;
		}
	}

	.dna-textarea{
		.dna-character-count-wrap & {
			border-bottom:0;
			border-bottom-left-radius:0;
			border-bottom-right-radius:0;
		}
	}

	.dna-character-count {
		font-family: @font-base;
		position:absolute;
		top:0;
		right:0;
		width:50px;
		line-height:36px;
		font-size:14px;
		text-align:right;
		padding-right:12px;
		color:@form-font-color-placeholder;
		transition:0.2s all;

		.dna-input:focus ~ &,
		.dna-textarea:focus ~ & {
			color:@form-font-color-label;
		}

		@media @mq-l{
			line-height:44px;
		}
	}

	.dna-character-count-full {
		color:@form-font-color-error!important;
	}

//++++++++++++++++++++++++++++++++
//+ Textarea specific
//++++++++++++++++++++++++++++++++

.dna-character-count-wrap-textarea{
	padding-bottom:34px!important;

	@media @mq-l{
		padding-bottom:42px!important;
	}
}

	.dna-character-count {
		.dna-character-count-wrap-textarea & {
			top:auto;
			right:auto;
			left:0;
			bottom:2px;
			width:100%;
			border:1px solid @form-border-color;
			border-top:0;
			border-bottom-left-radius:@form-border-radius;
			border-bottom-right-radius:@form-border-radius;
			background-color: @form-component-background-color-default;
		}

		.dna-character-count-wrap-textarea .dna-textarea:disabled ~ & {
			border-color:@form-border-color-disabled!important;
		}

		.dna-character-count-wrap-textarea .dna-input-error ~ & {
			border-color:@form-border-color-error!important;
		}

		.dna-character-count-wrap-textarea .dna-textarea:hover ~ &,
		.dna-character-count-wrap-textarea .dna-textarea:focus ~ & {
			border-color:@form-border-color-focused;
		}

		.dna-character-count-wrap-hover & {
			border-color:@form-border-color-focused;
		}
	}

//++++++++++++++++++++++++++++++++
//+ Arrow field overrides
//++++++++++++++++++++++++++++++++

.dna-character-count-wrap-textarea.dna-input-arrow-bottom .dna-input-arrow{
	bottom:-13px;
	z-index:5;
}

.dna-character-count-wrap.dna-input-arrow-left .dna-character-count{
	left:12px;
	width:~"calc(100% - 12px)";
}

.dna-character-count-wrap.dna-input-arrow-right .dna-character-count{
	right:12px;
	width:~"calc(100% - 12px)";
}

.dna-character-count-wrap.dna-input-arrow-top:not(.dna-character-count-wrap-textarea) .dna-character-count{
	top:15px;
}

.dna-character-count-wrap-hover.dna-input-arrow-bottom .dna-input-arrow {
	border-top-color:@form-border-color-focused;
}

.dna-character-count-wrap-hover.dna-input-arrow-top .dna-input-arrow {
	border-bottom-color:@form-border-color-focused;
}

.dna-character-count-wrap-hover.dna-input-arrow-left .dna-input-arrow {
	border-right-color:@form-border-color-focused;
}

.dna-character-count-wrap-hover.dna-input-arrow-right .dna-input-arrow {
	border-left-color:@form-border-color-focused;
}

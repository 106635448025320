/*csslint known-properties:false */
@import '../../../dna-core/components/global/global';
@import '../../../dna-core/components/variables/components/form';
@import '../input/input';
@import '../input-prefix/input-prefix';
@import '../select/select';

//++++++++++++++++++++++++++++++++
//+ DNA input-select-group
//++++++++++++++++++++++++++++++++

// Note: dna-input-select-group uses flexbox in a progresively enhanced manner
// to group elements. In <IE10 this behaviour is unsupported and layout will
// default to inline-block
.dna-input-select-group {
	.dna-clearfix;

	.flexbox & {
		display: flex;
		align-items: flex-end;
	}
	.flexboxtweener & {
		display: flexbox;
		box-align: end;
	}
	.flexboxlegacy & {
		display: box;
		box-align: end;
	}

	> .dna-form-field {
		display: inline-block;
		vertical-align: text-bottom;
		position: relative;

		.dna-input-text {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		.dna-select {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	.dna-form-field:first-of-type {
		width: 100%;
	}
	.dna-form-field:last-of-type {
		margin-left: -1px;
	}
}

.no-touchevents .dna-input-select-group:hover .dna-input,
.dna-input-select-group.is-active .dna-input,
.no-touchevents .dna-input-select-group:hover .dna-select,
.dna-input-select-group.is-active .dna-select{
	border-color:@form-border-color-focused;
}
@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.owner {
  padding: 8px;
  line-height: 1.2;
  text-align: center;

  @include xlarge {
    padding: 16px;
    display: flex;
    text-align: left;
  }
}

.heading {
  color: $color-nobel;
  font-size: 0.8em;

  @include large {
    font-size: 0.93em;
  }
}

.owner-info {
  margin-top: -45px;
  text-align: left;
  width: 100%;

  > a {
    &:hover {
      text-decoration: none;
    }
  }

  @include xlarge {
    float: left;
    margin-top: 0;
    text-align: center;
    width: 13%;
  }
}

.owner-raising-for {
  margin-left: 10px;
  margin-top: 10px;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;

  @include large {
    margin-left: 11px;
    margin-top: 15px;
    width: 88%;
  }

  @include xlarge {
    float: right;
    margin-left: 0;
    margin-top: 0;
    padding-left: 20px;
    width: 100%;
  }
}

.name {
  color: $color-nero;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  line-height: 1.3;
  margin-left: 10px;
  text-align: right;
  vertical-align: bottom;
  overflow: hidden;
  text-overflow: ellipsis;

  @include large {
    display: block;
    font-size: 1.1em;
    margin-left: 90px;
    margin-top: -20px;
    text-align: left;
  }

  @include xlarge {
    margin-left: 0;
    margin-top: 10px;
    text-align: center;
  }
}

.avatar {
  border: 2px solid $color-white;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  width: 80px;
  height: 80px;
  object-position: center;
  object-fit: cover;
}

@import '../../..//dna-core/components/global/global';
@import '../../..//dna-core/components/variables/components/button';

//++++++++++++++++++++++++++++++++
//+ DNA button-loading
//++++++++++++++++++++++++++++++++

.dna-button-loading(@color: @button-default-loading-color; @isButtonSmall: false) when (@isButtonSmall = false) {
	.dna-loading-button {
		.dna-loading-button-base;
		top: 14%;
		@media @mq-l {
			top: 18%;
		}
	}
	&.js-is-loading {
		.js-is-loading;
	}
}

.dna-button-loading(@color: @button-default-loading-color; @isButtonSmall) when (@isButtonSmall = true) {
	.dna-loading-button {
		.dna-loading-button-base;
		top: 7%;
		@media @mq-l {
			top: 14%;
		}
	}
	&.js-is-loading {
		.js-is-loading;
	}
}

// Sub-mixins
.js-is-loading() {
	position: relative;

	.dna-button-text {
		visibility: hidden;
	}
	.dna-loading-button {
		visibility: visible!important;
	}
}

.dna-loading-button-base() {
	.dna-loading(@color: @color);
	position: absolute;
	left: calc(~'50% - 12.5px');
	visibility: hidden!important;
}
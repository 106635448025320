@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.fixed-footer {
  background-color: $color-white;
  padding: 12px;
  position: fixed;
  bottom: 0;
  z-index: 5;
  width: 100%;
  display: flex;
  border-top: 1px $color-very-light-grey solid;

  @include large {
    display: none;
  }
}

.fixed-share-button {
  $percentage: 40%;
  $pixels: 10px;

  flex: 1;
  margin-left: 12px;
  min-width: 114px;
  max-width: calc(40% - 10px);
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.nav-bar {
  z-index: 5;
  width: 100%;
  overflow-x: scroll;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.nav-bar::-webkit-scrollbar {
  display: none;
}

.nav-bar-container {
  white-space: nowrap;
  margin: 0;
  padding-left: 12px;

  @include large {
    margin: 0;
    display: flex;
    align-items: center;
    padding-left: 0;
  }
}

.fixed {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
}

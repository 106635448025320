@import '~@justgiving/styles/src/_settings';

.slider {
  display: flex;

  input[type='range'] {
    -webkit-appearance: none;
    margin-top: 21px;
    margin-bottom: 21px;
    flex: 1;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 3px;
    border: none;
    border-radius: 3px;
    background-color: $color-very-light-grey;
  }

  input[type='range']::-ms-track {
    width: 100%;
    height: 5px;
    border: none;
    border-radius: 3px;
    background-color: $color-very-light-grey;
    border-color: transparent;
    border-width: 20px 0;
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 4px $color-brand-primary solid;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    margin-top: -8px;
    background-color: $color-white;
    transform: translateY(-25%);
  }

  input[type='range']::-ms-thumb {
    border: 4px $color-brand-primary solid;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    margin-top: -10px;
    background-color: $color-white;
    transform: translateY(-25%);
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background: $color-very-light-grey;
  }

  input[type='range']:focus::-ms-track {
    background: $color-very-light-grey;
  }
}

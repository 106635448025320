@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  max-width: 1280px;
  margin: 0 auto;
}

.content {
  flex-direction: column;

  @include large {
    flex-direction: row;
  }
}

.avatar-container {
  flex-basis: content;
  margin-right: 30px;
}

.avatar-image {
  border: 2px solid $color-white;
  border-radius: 50%;
  width: 64px;

  @include medium {
    min-width: 105px;
  }
}

.location-pin {
  position: absolute;
  top: -5px;
}

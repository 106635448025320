@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  font-family: $global-font-family;
  margin: 0 auto;
  text-align: center;
  position: relative;
  background-color: $color-white;
  min-height: 100vh;

  @include large {
    background-color: transparent;
    margin: auto;
    min-height: 30vh;
  }
}

.body {
  background-color: white;
}

.social-actions {
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: auto;
  padding: 20px;

  @include large {
    max-width: 60%;
  }
}

.copy-url-action {
  border: 1px $color-nobel solid;
  background-color: $color-nobel;
  min-height: 50px;
  width: 100%;
  border-radius: 4px 4px 0 0;
  text-align: center;
  padding: 16px;
  background-image: url('icon-links-white.svg');
  background-repeat: no-repeat;
  background-position: 14px 16px;
  background-size: 18px;
  color: $color-white;
  outline: 0;

  &:hover {
    background-color: $color-empress;
    border: 1px $color-empress solid;
  }
}

.copy-url {
  color: $color-nobel;
  border: 1px $color-nobel solid;
  width: 100%;
  border-radius: 0 0 4px 4px;
  height: 50px;
  font-size: 0.8em;
  padding: 0 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.facebook-container {
  margin-bottom: 16px;
}

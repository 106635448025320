// Chevron icons

// COLOUR
// Grey
.icon-chevron-up-grey() {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlPmljb24gLyBjaGV2cm9udXA8L3RpdGxlPjxwYXRoIGQ9Ik03LjI3Ny44NDZsNi40IDYuNGMuMTIzLjEyMy4xMjMuMzcgMCAuNTU0bC0uOC44Yy0uMTIzLjEyMy0uMzcuMTIzLS41NTQgMGwtNS4xNy01LjE3Yy0uMDYtLjA2LS4xODQtLjA2LS4yNDUgMEwxLjczOCA4LjZjLS4xMjMuMTIzLS4zNy4xMjMtLjU1MyAwbC0uOC0uOGMtLjEyMy0uMTIzLS4xMjMtLjM3IDAtLjU1NGw2LjQtNi40Yy4xMjMtLjEyMy4zNy0uMTIzLjQ5MiAweiIgZmlsbD0iIzk5OSIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
}

.icon-chevron-down-grey() {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHRpdGxlPmljb24gLyBjaGV2cm9uZG93biBjb3B5PC90aXRsZT48cGF0aCBkPSJNNi43MjMgOC4xNTRsLTYuNC02LjRDLjIgMS42My4yIDEuMzg0LjMyMyAxLjJsLjgtLjhjLjEyMy0uMTIzLjM3LS4xMjMuNTU0IDBsNS4xNyA1LjE3Yy4wNi4wNi4xODQuMDYuMjQ1IDBMMTIuMjYyLjRjLjEyMy0uMTIzLjM3LS4xMjMuNTUzIDBsLjguOGMuMTIzLjEyMy4xMjMuMzcgMCAuNTU0bC02LjQgNi40Yy0uMTIzLjEyMy0uMzcuMTIzLS40OTIgMHoiIGZpbGw9IiM5OTkiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
}

.icon-chevron-left-grey() {
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDE2IDE2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikljb25zLXNtLSgxNnB4KSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEzNi4wMDAwMDAsIC0zNTUuMDAwMDAwKSIgZmlsbD0iIzk5OTk5OSI+CiAgICAgICAgICAgIDxnIGlkPSJpY29uLS8tY2hldnJvbmxlZnQtLy1zbS0vLWdyZXkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEzNi4wMDAwMDAsIDM1NS4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xMC44NDYxNTM4LDEuMzIzMDc2OTIgQzEwLjk2OTIzMDgsMS4yIDExLjIxNTM4NDYsMS4yIDExLjQsMS4zMjMwNzY5MiBMMTIuMiwyLjEyMzA3NjkyIEMxMi4zMjMwNzY5LDIuMjQ2MTUzODUgMTIuMzIzMDc2OSwyLjQ5MjMwNzY5IDEyLjIsMi42NzY5MjMwOCBMNy4wMzA3NjkyMyw3Ljg0NjE1Mzg1IEM2Ljk2OTIzMDc3LDcuOTA3NjkyMzEgNi45NjkyMzA3Nyw4LjAzMDc2OTIzIDcuMDMwNzY5MjMsOC4wOTIzMDc2OSBMMTIuMiwxMy4yNjE1Mzg1IEMxMi4zMjMwNzY5LDEzLjM4NDYxNTQgMTIuMzIzMDc2OSwxMy42MzA3NjkyIDEyLjIsMTMuODE1Mzg0NiBMMTEuNCwxNC42MTUzODQ2IEMxMS4yNzY5MjMxLDE0LjczODQ2MTUgMTEuMDMwNzY5MiwxNC43Mzg0NjE1IDEwLjg0NjE1MzgsMTQuNjE1Mzg0NiBMNC40NDYxNTM4NSw4LjIxNTM4NDYyIEM0LjI2MTUzODQ2LDguMDkyMzA3NjkgNC4yNjE1Mzg0Niw3Ljg0NjE1Mzg1IDQuNDQ2MTUzODUsNy43MjMwNzY5MiBMMTAuODQ2MTUzOCwxLjMyMzA3NjkyIFoiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
}

// Dark grey
.icon-chevron-left-darkgrey() {
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDE2IDE2IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ikljb25zLXNtLSgxNnB4KSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIyMi4wMDAwMDAsIC0zNTMuMDAwMDAwKSIgZmlsbD0iIzczNzM3MyI+CiAgICAgICAgICAgIDxnIGlkPSJpY29uLS8tY2hldnJvbmxlZnQtLy1zbS0vLWRhcmtncmV5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyMjIuMDAwMDAwLCAzNTMuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTAuODQ2MTUzOCwxLjMyMzA3NjkyIEMxMC45NjkyMzA4LDEuMiAxMS4yMTUzODQ2LDEuMiAxMS40LDEuMzIzMDc2OTIgTDEyLjIsMi4xMjMwNzY5MiBDMTIuMzIzMDc2OSwyLjI0NjE1Mzg1IDEyLjMyMzA3NjksMi40OTIzMDc2OSAxMi4yLDIuNjc2OTIzMDggTDcuMDMwNzY5MjMsNy44NDYxNTM4NSBDNi45NjkyMzA3Nyw3LjkwNzY5MjMxIDYuOTY5MjMwNzcsOC4wMzA3NjkyMyA3LjAzMDc2OTIzLDguMDkyMzA3NjkgTDEyLjIsMTMuMjYxNTM4NSBDMTIuMzIzMDc2OSwxMy4zODQ2MTU0IDEyLjMyMzA3NjksMTMuNjMwNzY5MiAxMi4yLDEzLjgxNTM4NDYgTDExLjQsMTQuNjE1Mzg0NiBDMTEuMjc2OTIzMSwxNC43Mzg0NjE1IDExLjAzMDc2OTIsMTQuNzM4NDYxNSAxMC44NDYxNTM4LDE0LjYxNTM4NDYgTDQuNDQ2MTUzODUsOC4yMTUzODQ2MiBDNC4yNjE1Mzg0Niw4LjA5MjMwNzY5IDQuMjYxNTM4NDYsNy44NDYxNTM4NSA0LjQ0NjE1Mzg1LDcuNzIzMDc2OTIgTDEwLjg0NjE1MzgsMS4zMjMwNzY5MiBaIj48L3BhdGg+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
}

// White
.icon-chevron-left-white() {
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjE2cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDE2IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy4zLjMgKDEyMDcyKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT4mbHQ7PC90aXRsZT4KICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgogICAgPGRlZnM+PC9kZWZzPgogICAgPGcgaWQ9IkdhbGxlcnkiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHNrZXRjaDp0eXBlPSJNU1BhZ2UiPgogICAgICAgIDxnIGlkPSJHYWxsZXJ5LWRlc2t0b3AtQ29weSIgc2tldGNoOnR5cGU9Ik1TQXJ0Ym9hcmRHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTIzMS4wMDAwMDAsIC00NTguMDAwMDAwKSIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjMiPgogICAgICAgICAgICA8ZyBpZD0iJmx0OyIgc2tldGNoOnR5cGU9Ik1TTGF5ZXJHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjMyLjAwMDAwMCwgNDYwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTEuMTczOTEzMDQsMTEuODY5NTY1MiBMMTIuNTI1MTExMiwyMy4yMjA3NjMzIiBpZD0iTGluZSIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCI+PC9wYXRoPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTEyLjUyNTExMTcsMC41MTgzNjY1NDQgTDEuMTczOTEzMDQsMTEuODY5NTY1MiIgaWQ9IkxpbmUtQ29weSIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCI+PC9wYXRoPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=');
}

.icon-chevron-right-white() {
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjE3cHgiIGhlaWdodD0iMjhweCIgdmlld0JveD0iMCAwIDE3IDI4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggMy4zLjMgKDEyMDcyKSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT4mbHQ7IGNvcHk8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iR2FsbGVyeSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+CiAgICAgICAgPGcgaWQ9IkdhbGxlcnktZGVza3RvcC1Db3B5IiBza2V0Y2g6dHlwZT0iTVNBcnRib2FyZEdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTE5My4wMDAwMDAsIC00NTguMDAwMDAwKSIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlPSIjRkZGRkZGIiBzdHJva2Utd2lkdGg9IjMiPgogICAgICAgICAgICA8ZyBpZD0iJmx0Oy1jb3B5IiBza2V0Y2g6dHlwZT0iTVNMYXllckdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjAxLjc4MjYwOSwgNDcyLjAwMDAwMCkgcm90YXRlKC0xODAuMDAwMDAwKSB0cmFuc2xhdGUoLTEyMDEuNzgyNjA5LCAtNDcyLjAwMDAwMCkgdHJhbnNsYXRlKDExOTUuMDAwMDAwLCA0NjAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMS4xNzM5MTMwNCwxMS44Njk1NjUyIEwxMi41MjUxMTEyLDIzLjIyMDc2MzMiIGlkPSJMaW5lIiBza2V0Y2g6dHlwZT0iTVNTaGFwZUdyb3VwIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTIuNTI1MTExNywwLjUxODM2NjU0NCBMMS4xNzM5MTMwNCwxMS44Njk1NjUyIiBpZD0iTGluZS1Db3B5IiBza2V0Y2g6dHlwZT0iTVNTaGFwZUdyb3VwIj48L3BhdGg+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==');
}

.icon-chevron-left-purple() {
    background-image:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMTBweCIgaGVpZ2h0PSIxNnB4IiB2aWV3Qm94PSIwIDAgMTAgMTYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiPiAgICAgICAgPHRpdGxlPiZsdDsgYXJyb3c8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+ICAgICAgICA8cGF0aCBpZD0icGF0aC0xIiBkPSJNMCwwIEw5LjQyNSwwIEw5LjQyNSwxNiBMMCwxNiI+PC9wYXRoPiAgICA8L2RlZnM+ICAgIDxnIGlkPSJETkEtQ0FVU0UtSEVBREVSUyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+ICAgICAgICA8ZyBpZD0iQXJ0Ym9hcmQtNyIgc2tldGNoOnR5cGU9Ik1TQXJ0Ym9hcmRHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMuMDAwMDAwLCAtMS4wMDAwMDApIj4gICAgICAgICAgICA8ZyBpZD0iJmx0Oy1hcnJvdyIgc2tldGNoOnR5cGU9Ik1TTGF5ZXJHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMy4wMDAwMDAsIDEuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxtYXNrIGlkPSJtYXNrLTIiIHNrZXRjaDpuYW1lPSJDbGlwIDIiIGZpbGw9IndoaXRlIj4gICAgICAgICAgICAgICAgICAgIDx1c2UgeGxpbms6aHJlZj0iI3BhdGgtMSI+PC91c2U+ICAgICAgICAgICAgICAgIDwvbWFzaz4gICAgICAgICAgICAgICAgPGcgaWQ9IkNsaXAtMiI+PC9nPiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMC4xMzM2LDcuNjc3OCBMNy42Nzc2LDAuMTMzOCBDNy44NTY2LC0wLjA0NDIgOC4xNDQ2LC0wLjA0NDIgOC4zMjM2LDAuMTMzOCBMOS4yOTA2LDEuMTAwOCBDOS40Njk2LDEuMjc5OCA5LjQ2OTYsMS41Njc4IDkuMjkwNiwxLjc0NjggTDMuMTk4Niw3LjgzODggQzMuMTA5Niw3LjkyNzggMy4xMDk2LDguMDcyOCAzLjE5ODYsOC4xNjE4IEw5LjI5MDYsMTQuMjUzOCBDOS40Njg2LDE0LjQzMTggOS40Njg2LDE0LjcyMDggOS4yODk2LDE0Ljg5ODggTDguMzIyNiwxNS44NjY4IEM4LjE0NDYsMTYuMDQ0OCA3Ljg1NTYsMTYuMDQ0OCA3LjY3NzYsMTUuODY2OCBMMC4xMzM2LDguMzIyOCBDLTAuMDQ0NCw4LjE0NDggLTAuMDQ0NCw3Ljg1NTggMC4xMzM2LDcuNjc3OCIgaWQ9IkZpbGwtMSIgZmlsbD0iI0FDMjlCNSIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCIgbWFzaz0idXJsKCNtYXNrLTIpIj48L3BhdGg+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+');
}

.icon-chevron-left-darkpurple() {
    background-image:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iOXB4IiBoZWlnaHQ9IjE0cHgiIHZpZXdCb3g9IjAgMCA5IDE0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbG5zOnNrZXRjaD0iaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoL25zIj4gICAgICAgIDx0aXRsZT5pY29uIC8gY2hldnJvbmxlZnQgLyBzbSAvIHB1cnBsZSBjb3B5PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc2tldGNoOnR5cGU9Ik1TUGFnZSI+ICAgICAgICA8ZyBpZD0iSWNvbnMtc20tKDE2cHgpIiBza2V0Y2g6dHlwZT0iTVNBcnRib2FyZEdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNTUwLjAwMDAwMCwgLTM1NC4wMDAwMDApIiBmaWxsPSIjODgyQzg0Ij4gICAgICAgICAgICA8ZyBpZD0iaWNvbi0vLWNoZXZyb25sZWZ0LS8tc20tLy1wdXJwbGUtY29weSIgc2tldGNoOnR5cGU9Ik1TTGF5ZXJHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTQ2LjAwMDAwMCwgMzUzLjAwMDAwMCkiPiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMTAuODQ2MTUzOCwxLjMyMzA3NjkyIEMxMC45NjkyMzA4LDEuMiAxMS4yMTUzODQ2LDEuMiAxMS40LDEuMzIzMDc2OTIgTDEyLjIsMi4xMjMwNzY5MiBDMTIuMzIzMDc2OSwyLjI0NjE1Mzg1IDEyLjMyMzA3NjksMi40OTIzMDc2OSAxMi4yLDIuNjc2OTIzMDggTDcuMDMwNzY5MjMsNy44NDYxNTM4NSBDNi45NjkyMzA3Nyw3LjkwNzY5MjMxIDYuOTY5MjMwNzcsOC4wMzA3NjkyMyA3LjAzMDc2OTIzLDguMDkyMzA3NjkgTDEyLjIsMTMuMjYxNTM4NSBDMTIuMzIzMDc2OSwxMy4zODQ2MTU0IDEyLjMyMzA3NjksMTMuNjMwNzY5MiAxMi4yLDEzLjgxNTM4NDYgTDExLjQsMTQuNjE1Mzg0NiBDMTEuMjc2OTIzMSwxNC43Mzg0NjE1IDExLjAzMDc2OTIsMTQuNzM4NDYxNSAxMC44NDYxNTM4LDE0LjYxNTM4NDYgTDQuNDQ2MTUzODUsOC4yMTUzODQ2MiBDNC4yNjE1Mzg0Niw4LjA5MjMwNzY5IDQuMjYxNTM4NDYsNy44NDYxNTM4NSA0LjQ0NjE1Mzg1LDcuNzIzMDc2OTIgTDEwLjg0NjE1MzgsMS4zMjMwNzY5MiBaIiBpZD0iaWNvbi0vLWNoZXZyb25sZWZ0LS8tbWVkLS8tcHVycGxlIiBza2V0Y2g6dHlwZT0iTVNTaGFwZUdyb3VwIj48L3BhdGg+ICAgICAgICAgICAgPC9nPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+');
}

// SIZE
// Small
.icon-chevron-left-sm-square() {
	width: 16px;
	height: 16px;
}

.icon-chevron-left-sm() {
    width: 10px;
    height: 16px;
}

.icon-chevron-up-sm() {
	width:14px;
	height:9px;
}

.icon-chevron-down-sm() {
	width:14px;
	height:9px;
}

// Medium
.icon-chevron-left-med() {
	width:16px;
	height:28px;
}

.icon-chevron-right-med() {
	width:17px;
	height:28px;
}

// CLASSES
.dna-icon-chevron-left-sm-darkgrey {
	.dna-icon;
	.icon-chevron-left-sm-square;
	.icon-chevron-left-darkgrey;
}

.dna-icon-chevron-down-sm-grey {
	.dna-icon;
	.icon-chevron-down-sm;
	.icon-chevron-down-grey;
}

.dna-icon-chevron-left-sm-grey {
	.dna-icon;
	.icon-chevron-left-sm-square;
	.icon-chevron-left-grey;
}

.dna-icon-chevron-left-sm-purple {
    .dna-icon;
    .icon-chevron-left-sm;
    .icon-chevron-left-purple;
}

.dna-icon-chevron-left-sm-darkpurple {
    .dna-icon;
    .icon-chevron-left-sm;
    .icon-chevron-left-darkpurple;
}

.dna-icon-chevron-left-med-white {
	.dna-icon;
	.icon-chevron-left-med;
	.icon-chevron-left-white;
}

.dna-icon-chevron-right-med-white {
	.dna-icon;
	.icon-chevron-right-med;
	.icon-chevron-right-white;
}

.dna-icon-chevron-up-sm-grey {
	.dna-icon;
	.icon-chevron-up-sm;
	.icon-chevron-up-grey;
}

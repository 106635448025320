@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';
@import './SocialShareButton.scss';

.twitter {
  @extend .share-button;

  background-image: url('./images/mobile-twitter.svg');

  @include large {
    background-image: url('./images/round-twitter.svg');
  }
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  border-top: 1px solid $color-very-light-grey;
  color: $color-nero;
  font-family: $global-font-family;
  font-size: 1rem;
  margin-top: 20px;
  padding: 16px;

  @include large {
    padding: 16px 0;
  }

  @include xlarge {
    margin-top: 0;
  }
}

.donation-list {
  clear: both;

  @include large {
    margin-left: 55px;
  }

  @include xlarge {
    margin-left: 0;
  }
}

.action {
  text-decoration: none;
  margin-bottom: 0;

  @include medium {
    margin-bottom: 20px;
  }
}

.show-more-button-container {
  margin: 20px auto 0;
  max-width: 280px;
  padding: 0 30px;
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';
@import './SocialShareButton.scss';

.share-button-container {
  @include large {
    display: none;
  }

  .sms {
    @extend .share-button;

    background-image: url('./images/mobile-sms.svg');
  }
}

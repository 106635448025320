@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.col {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @include large {
    justify-content: flex-start;
    flex-direction: row;
  }
}

.icon-container {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: $color-white-smoke;

  svg {
    display: block;
  }

  &.update {
    padding: 13px 13px;
  }

  &.thank {
    padding: 15px 13px;
  }

  &.milestone {
    padding: 13px 13px;
  }
}

.icon {
  fill: $color-nobel;
}

.thank {
  &:hover {
    text-decoration: none;
  }

  .icon-container {
    background-color: #ff7ce4; // if applicable stick color in toolkit
  }
}

.update {
  &:hover {
    text-decoration: none;
  }

  .icon-container {
    background-color: $color-brand-secondary;
  }
}

.milestone {
  &:hover {
    text-decoration: none;
  }

  .icon-container {
    background-color: $color-brand-primary;
  }
}

.icon {
  fill: $color-white;
}

.title {
  font-weight: 400;
  color: $color-nero;
  @include font-size(sm-font-sizes(small));

  @include large {
    margin-left: $global-spacing-unit-sm;
    @include font-size(font-sizes(medium));
  }
}

@import '../../../dna-core/components/global/global';
@import '../../../dna-core/components/variables/components/progress';

@import './totaliser-variables';
@import './totaliser-mixins';
@import './totaliser-base';

//-------------------------------------------------
// PUBLIC CLASSES
// - use these classes in dom
//-------------------------------------------------

// totaliser small
.dna-totaliser-s {
	.dna-totaliser();
	.font-size-settings(@font-size-value-s-m);
	.container-size-settings(@container-size-s-s);

	.dna-totaliser-level-wrapper {
		margin: 3px;
		width: @container-size-s-s - 7px;
		height: @container-size-s-s - 7px;
	}
	.dna-totaliser-level {
		left: -350px;
	}
	.dna-totaliser-wave {
		width: 400px;
		height: 100px;
	}
	.dna-totaliser-ring {
		border: 2px solid @totaliser-border-colour;

		span {
			border: 2px solid @totaliser-rim-colour;
		}
	}
	&.digits-three {
		.font-size-settings(@font-size-value-s-s);
	}
	&.digits-four {
		.font-size-settings(@font-size-value-s-xs);
	}
	&.digits-five {
		.font-size-settings(@font-size-value-s-xxs);
	}

	.dna-totaliser-percentage {
		margin-left: 2px;
	}

	@media @mq-m {
		.font-size-settings(@font-size-value-s-l);
		.container-size-settings(@container-size-s-m);

		.dna-totaliser-level-wrapper {
			margin: 4px;
			width: @container-size-s-m - 9px;
			height: @container-size-s-m - 9px;
		}
		.dna-totaliser-ring {
			border: 3px solid @totaliser-border-colour;
		}
		&.digits-three {
			.font-size-settings(@font-size-value-s-m);
		}
		&.digits-four {
			.font-size-settings(@font-size-value-s-s);
		}
		&.digits-five {
			.font-size-settings(@font-size-value-s-xs);
		}
	}
}

// totaliser medium
.dna-totaliser-m {
	.dna-totaliser();
	.font-size-settings(@font-size-value-m-m);
	.container-size-settings(@container-size-m-s);

	.dna-totaliser-level-wrapper {
		margin: 7px;
		width: @container-size-m-s - 16px;
		height: @container-size-m-s - 16px;
	}
	.dna-totaliser-level {
		left: -700px;
	}
	.dna-totaliser-wave {
		width: 800px;
		height: 200px;
	}
	.dna-totaliser-ring {
		border: 5px solid @totaliser-border-colour;

		span {
			border: 3px solid @totaliser-rim-colour;
		}
	}
	&.digits-three {
		.font-size-settings(@font-size-value-m-s);
	}
	&.digits-four {
		.font-size-settings(@font-size-value-m-xs);
	}
	&.digits-five {
		.font-size-settings(@font-size-value-m-xxs);
	}

	@media @mq-m {
		.font-size-settings(@font-size-value-m-l);
		.container-size-settings(@container-size-m-m);

		.dna-totaliser-level-wrapper {
			margin: 9px;
			width: @container-size-m-m - 20px;
			height: @container-size-m-m - 20px;
		}
		.dna-totaliser-ring {
			border: 6px solid @totaliser-border-colour;

			span {
				border: 4px solid @totaliser-rim-colour;
			}
		}
		&.digits-three {
			.font-size-settings(@font-size-value-m-m);
		}
		&.digits-four {
			.font-size-settings(@font-size-value-m-s);
		}
		&.digits-five {
			.font-size-settings(@font-size-value-m-xs);
		}
	}
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';
@import './SocialShareButton.scss';

.whatsapp {
  @extend .share-button;

  background-image: url('./images/mobile-whatsapp.svg');

  @include large {
    background-image: url('./images/round-whatsapp.svg');
  }
}

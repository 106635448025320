@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.wrapper {
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  @include xlarge {
    flex-direction: row;
  }
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  font-family: $global-font-family;
  margin: 0 auto;
  text-align: center;
  position: relative;
  background-color: $color-white;
  min-height: 100vh;

  @include large {
    background-color: transparent;
    margin: auto;
    min-height: 30vh;
  }
}

.body {
  background-color: white;
}

.social-actions {
  max-width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  margin: auto;

  @include large {
    max-width: 60%;
  }
}

.copy-to-clipboard-button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: $color-nobel;

  &:hover {
    background-color: $color-empress;
  }
}

.copy-to-clipboard-input {
  color: $color-empress !important;
  @include font-size(sm-font-sizes(small));
  border: 1px $color-nobel solid;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.action-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include large {
    flex-direction: row;
  }
}

.breakline {
  position: relative;
  width: 30px;

  &:after {
    content: '';
    height: 1px;
    width: 30px;
    background-position: center center;
    border-bottom: 1px $color-very-light-grey solid;
    position: absolute;
    top: 50%;
    left: 0px;
  }

  @include large {
    &:after {
      left: 0;
    }
  }
}

.timer {
  padding-top: 3px;
}

@import '~../../../dna-core/components/global/global';
@import '~../../../dna-core/components/variables/components/form';

//++++++++++++++++++++++++++++++++
//+ DNA label
//++++++++++++++++++++++++++++++++

.dna-label {
	font-family: @font-base;
	display: block;
	margin:0 0 @space-s 1px;
	color:@form-font-color-label;
	font-size:@form-font-size-s;
	line-height: 1.5625;
	word-wrap: break-word;

	@media @mq-l{
		font-size:@form-font-size-m;
		margin:0 0 @space-s 1px;
	}
}

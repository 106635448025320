.errors {
    height: 100%;
    padding-top: 32px;
    padding-bottom: 300px;
    color: #ffffff;
    text-shadow: 0px 0px 5px rgba(38, 38, 38, 0.7);
    position: relative;

    @media screen {
        @media (min-width: 64em ) {
            padding-bottom: 500px;
        }
    }
}

.errors-svg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.errors-heading {
    margin: auto;
    max-width: 1280px;
    color: #ffffff;
    font-size: 1.6em;
    line-height: 1.4;
    padding: 0 16px;
    padding-bottom: 16px;

    @media screen {
        @media (min-width: 64em ) {
            padding-right: 200px;
        }
    }
}

.errors-body {
    margin: auto;
    max-width: 1280px;
    color: #ffffff;
    padding: 16px;

    @media screen { @media (min-width: 64em ) {
            padding-right: 200px;
        }
    }
}

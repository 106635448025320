@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.supporter-message-box {
  position: relative;
}

.supporter-message-box:after,
.supporter-message-box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.supporter-message-box:after {
  border-color: rgba(136, 183, 213, 0);
  border-top-color: $color-white;
  border-width: 10px;
  margin-left: -10px;
}

.supporter-message-box:before {
  border-color: rgba(194, 225, 245, 0);
  border-top-color: $color-very-light-grey;
  border-width: 12px;
  margin-left: -12px;
}

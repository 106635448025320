@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.progress {
  font-size: 0.95em;

  @include small {
    display: flex;
    font-size: 1em;
    margin-bottom: 24px;
    padding: 0 16px;
    width: 100%;
  }

  @include medium {
    width: 65%;
  }

  @include large {
    display: flex;
    padding: 0;
    order: 1;
    width: 50%;
  }

  @include xlarge {
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;
  }
}

.totaliser-avatar-wrapper {
  width: 148px;
  height: 148px;
  img {
    width: 100%;
    height: 100%;
    border: none;
  }
}

.target {
  display: inline-block;
  font-size: 0.9em;
  margin-left: 16px;
  margin-bottom: 15px;
  text-align: center;
  width: 100%;

  @include small {
    text-align: left;
    vertical-align: top;
  }

  @include medium {
    width: 50%;
  }

  @include large {
    margin-left: 20px;
    order: 2;
    text-align: left;
  }

  @include xlarge {
    display: block;
    margin: 0;
    text-align: center;
    width: 100%;
  }
}

.value {
  font-size: 1em;
  line-height: 25px;

  @include large {
    font-size: 1.2em;
  }
}

.raised {
  display: block;
  margin-bottom: 5px;
  margin-top: 10px;
}

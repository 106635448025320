// Facebook icons
.icon-facebook-white() {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAxMCAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+RmlsbCAyIENvcHk8L3RpdGxlPjxwYXRoIGQ9Ik0yLjU1NCAyMC44NTd2LTkuMzgzSDB2LTMuMzhoMi41NTRWNS4yMWMwLTIuMjY2IDEuNTEtNC4zNSA0Ljk5LTQuMzUgMS40MDcgMCAyLjQ1LjEzNSAyLjQ1LjEzNUw5LjkxIDQuMTQ1cy0xLjA2LS4wMDgtMi4yMi0uMDA4Yy0xLjI1NiAwLTEuNDU0LjU2LTEuNDU0IDEuNDkydjIuNDY1aDMuNzc0bC0uMTYgMy4zOEg2LjIzNXY5LjM4MkgyLjU1NCIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
}
.icon-facebook-blue() {
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+Cjxzdmcgd2lkdGg9IjEwcHgiIGhlaWdodD0iMjBweCIgdmlld0JveD0iMCAwIDEwIDIwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCAzLjcuMiAoMjgyNzYpIC0gaHR0cDovL3d3dy5ib2hlbWlhbmNvZGluZy5jb20vc2tldGNoIC0tPgogICAgPHRpdGxlPkZpbGwgMiBDb3B5IDI8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZGVmcz48L2RlZnM+CiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iQ3Jvd2RmdW5kaW5nLS0tVXBkYXRlcy12MS0tLVhMIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjYyLjAwMDAwMCwgLTIyMTQuMDAwMDAwKSIgZmlsbD0iIzNCNTk5OCI+CiAgICAgICAgICAgIDxnIGlkPSJTaGFyZS1GYWNlYm9vayIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjYyLjAwMDAwMCwgMjIwMy4wMDAwMDApIj4KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0yLjU1MzcxNTE2LDMxIEwyLjU1MzcxNTE2LDIxLjYxNjE4MzMgTDAsMjEuNjE2MTgzMyBMMCwxOC4yMzczMzIxIEwyLjU1MzcxNTE2LDE4LjIzNzMzMjEgQzIuNTUyNTUyOCwxOC4yMzczMzIxIDIuNTUzNzE1MTYsMTYuODU0ODY5NyAyLjU1MzcxNTE2LDE1LjM1MDUyNDggQzIuNTUzNzE1MTYsMTMuMDg0NDE0OSA0LjA2MzYyNjg2LDExIDcuNTQ0OTA5MDIsMTEgQzguOTUxMzcwMjYsMTEgOS45OTI4NDg5OSwxMS4xMzU0MjQ5IDkuOTkyODQ4OTksMTEuMTM1NDI0OSBMOS45MTAzMjExLDE0LjI4NzQzOTMgQzkuOTA5MTU4NzQsMTQuMjg3NDM5MyA4Ljg0OTA4MjE3LDE0LjI3OTUzOTYgNy42ODkwNDIyNCwxNC4yNzk1Mzk2IEM2LjQzMzY4ODQsMTQuMjc5NTM5NiA2LjIzNjA4NjQxLDE0LjgzOTI5NTggNi4yMzYwODY0MSwxNS43NzE0NzA1IEM2LjIzNjA4NjQxLDE2LjUwNzI3OTEgNi4yMzYwODY0MSwxNC4yMDM5MjczIDYuMjM2MDg2NDEsMTguMjM3MzMyMSBMMTAuMDEwMjg0NSwxOC4yMzczMzIxIEw5Ljg0ODcxNTc4LDIxLjYxNjE4MzMgTDYuMjM2MDg2NDEsMjEuNjE2MTgzMyBMNi4yMzYwODY0MSwzMSBMMi41NTM3MTUxNiwzMSIgaWQ9IkZpbGwtMi1Db3B5LTIiPjwvcGF0aD4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
}

.icon-facebook-sm() {
	width:10px;
	height:21px;
}

.dna-icon-facebook-sm-white {
	.dna-icon;
	.icon-facebook-sm;
	.icon-facebook-white;
}

.dna-icon-facebook-sm-blue {
	.dna-icon;
	.icon-facebook-sm;
	.icon-facebook-blue;
}

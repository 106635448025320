@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.modal {
  @include large {
    width: 600px;
    border-radius: 8px;
    -webkit-box-shadow: 8px 8px 20px -15px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 8px 8px 20px -15px rgba(0, 0, 0, 0.75);
    box-shadow: 8px 8px 20px -15px rgba(0, 0, 0, 0.75);
  }

  &,
  .modal-background {
    width: 100%;
    height: 100%;
    padding: 24px;

    @include large {
      width: 600px;
      height: auto;
      padding: 20px;
    }
  }
}

.title {
  font-size: 18px;
  margin-top: 18px;
  margin-bottom: 10px;
  text-align: center;

  @include large {
    font-size: 24px;
    margin-top: 0px;
  }
}

.description {
  width: 100%;

  p {
    margin: 0;
    text-align: center;
  }

  @include large {
    display: inline;

    p {
      margin: 10px auto;
      max-width: 350px;
    }
  }
}

.button-container {
  padding: 10px 0;
  text-align: center;

  @include large {
    padding: 10px 20px;
  }
}

.close {
  display: inline-block;
  position: absolute;
  z-index: 999;
  top: 20px;
  right: 20px;

  @include large {
    top: 20px;
    right: 20px;
  }
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  background-color: $color-white;
  border-top: 1px solid $color-very-light-grey;
  clear: both;
  color: $color-nero;
  font-family: $global-font-family;
  font-size: 1rem;
  margin: 28px 16px 20px;

  @include large {
    border: 1px $color-very-light-grey solid;
    border-radius: 2px;
  }
}

.title {
  display: block;
  margin: 16px;
  text-align: center;

  @include large {
    margin: 24px;
  }
}

.comments {
  padding: 16px;
}

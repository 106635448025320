@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.modal {
  @include large {
    width: 638px;
    border-radius: 8px;
    -webkit-box-shadow: 8px 8px 20px -15px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 8px 8px 20px -15px rgba(0, 0, 0, 0.75);
    box-shadow: 8px 8px 20px -15px rgba(0, 0, 0, 0.75);
  }

  &,
  .modal-background {
    overflow: scroll;
    width: 100%;
    height: 100%;
    padding: 20px 0 0 20px;

    @include large {
      overflow: hidden;
      width: 638px;
      height: auto;
      padding: 24px;
    }
  }
}

.top-container {
  padding: 0px;
  max-width: 100%;

  @include large {
    padding: 24px;
  }
}

.title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;

  @include large {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.close {
  display: inline-block;
  position: absolute;
  z-index: 999;

  top: 30px;
  right: 25px;

  @include large {
    top: 20px;
    right: 20px;
  }
}

.description {
  width: 100%;
  padding-right: 20px;

  p {
    margin-bottom: 6px;
  }

  @include large {
    display: inline;
    width: auto;

    p {
      max-width: 340px;
      margin-bottom: 0;
    }
  }
}

.avatar {
  width: 58px;
  height: 58px;
  @include large {
    width: 98px;
    height: 98px;
  }

  img {
    cursor: unset;
    margin: 0;
    width: 58px;
    height: 58px;

    @include large {
      width: 98px;
      height: 98px;
      margin: 0 auto;
    }
  }
}

.button-container {
  padding: 0 20px 0 0;
  @include large {
    padding: 0 10px;
  }
}

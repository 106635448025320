@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  border-top: 1px solid $color-very-light-grey;
  color: $color-nero;
  font-family: $global-font-family;
  font-size: 1rem;
  overflow: hidden;

  @include small {
    padding: 0 16px;
  }

  @include large {
    padding: 0;
  }
}

.date {
  clear: both;
  padding: 4px 0;
  text-align: center;
  width: 100%;

  @include small {
    display: none;
  }

  @include medium {
    display: block;
  }
}

.updates-list {
  clear: both;
  position: relative;
}

.updates-timeline-container {
  height: 100%;
  position: absolute;
}

.updates-first-item-container {
  height: 20px;
  clear: both;
  position: relative;

  @include medium {
    left: 50px;
    padding-left: 0;
  }
}

.updates-timeline-marker {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%203%208%22%20width%3D%223px%22%20height%3D%228px%22%3E%3Ccircle%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20fill%3D%22%23CCC%22%20cx%3D%221.5%22%20cy%3D%221.5%22%20r%3D%221.5%22/%3E%3C/svg%3E');
  background-position: top left;
  background-repeat: repeat-y;
  display: block;
  height: 100%;
  left: 29px;
  width: 3px;
  position: absolute;

  @include medium {
    left: 79px;
  }
}

.updates-first-item-marker-container {
  float: left;
  position: relative;
  width: 60px;
}

.updates-first-item-description {
  float: left;
  width: calc(100% - 100px);

  @include medium {
    width: calc(100% - 120px);
    margin-left: 20px;
  }
}

.updates-first-item-marker {
  background: $color-brand-primary;
  border-radius: 50%;
  border: 2px solid $color-very-light-grey;
  display: block;
  height: 20px;
  margin: 0 0 4px 20px;
  width: 20px;
}

.avatar-container {
  float: left;
  width: 112px;

  @include small {
    display: none;
  }
}

.avatar-image {
  border: 2px solid $color-white;
  border-radius: 50%;
  float: right;
  height: 64px;
  width: 64px;
}

.supporters {
  width: 482px;
  background-color: $color-white;
  border: 1px $color-very-light-grey solid;
}

.supporter {
  margin-bottom: 8px;
}

.action {
  text-decoration: none;
  margin-bottom: 20px;
}

.action-box {
  clear: both;
  padding: 20px 0px;

  @include medium {
    padding: 50px 104px 0;
  }
}

.item {
  background-color: $color-white;
  border-radius: 2px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;

  @include medium {
    background: none;
    border: none;
    margin-bottom: 0;
  }
}

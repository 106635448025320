@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  border-top: 1px solid $color-very-light-grey;
  font-size: 1rem;
  text-align: center;
  padding: 40px 16px 0;
  background-image: url('images/MOBILE-bkgrd-hearts-280X380.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 98%;

  @include medium {
    background-image: url('images/iPAD-bkgrd-hearts-300x791.svg');
  }

  @include large {
    background-image: url('images/iPAD-bkgrd-hearts-300x1024.svg');
  }

  @include xlarge {
    background-image: url('images/DT-bkgrd-hearts-300x1600.svg');
  }
}

.action-create,
.action-about {
  margin: 0 auto 16px;
  display: block;
  max-width: 300px;
}

.white {
  a,
  a:link {
    color: $color-white;
  }
}

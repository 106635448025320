.clickable-element {
  border: none;
  padding: 0;
  margin: 0;
  text-align: left;
  cursor: pointer;
  background-color: transparent;
}

.clickable-element-block {
  @extend .clickable-element;
  display: block;
  width: 100%;
}

.clickable-element-inline {
  @extend .clickable-element;
  display: inline;
}

.clickable-element:focus,
.clickable-element-inline:focus {
  outline: 0;
}

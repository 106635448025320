@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

a.link {
  color: $color-brand-primary;
}

.text {
  @include font-size(font-sizes(large));
  color: $color-brand-primary;

  @include large {
    @include font-size(font-sizes(medium));
  }
}

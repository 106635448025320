@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container-funded {
  text-align: center;
  padding: 24px;

  background-image: url('images/MOBILE-bkgrd-hearts-280X380.svg');
  background-size: 98%;
  background-repeat: no-repeat;
  background-position: center;

  @include medium {
    background-image: url('images/iPAD-bkgrd-hearts-300x791.svg');
  }

  @include large {
    background-image: url('images/iPAD-bkgrd-hearts-300x1024.svg');
  }

  @include xlarge {
    background-image: url('images/DT-bkgrd-hearts-300x1600.svg');
  }
}

.message {
  max-width: 480px;
  margin: 0 auto 16px;
}

.action-container {
  margin: 24px auto 20px;
  width: 310px;

  @include large {
    width: 400px;
  }

  a:hover {
    text-decoration: none;
  }
}

.action-link {
  display: block;
  width: 260px;
  text-decoration: none;
  margin: 0 auto;
}

a.discover {
  color: white;
}

.timer {
  padding-top: 3px;
}

.closed {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $global-spacing-unit-sm;

  @include large {
    margin-bottom: 0px;
  }
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  padding: $global-spacing-unit-ms;
}

.story {
  margin-bottom: 2em;
  overflow: hidden;
  text-align: left;
  word-break: break-word;
  word-wrap: break-word;

  > p,
  img {
    margin-bottom: $global-spacing-unit-ml;
  }

  > h2 {
    margin-bottom: $global-spacing-unit-ms;
  }

  @include large {
    margin-left: 120px;
  }

  img {
    max-height: 500px;
  }
}

.video {
  margin-bottom: $global-spacing-unit-ml;

  iframe {
    width: 100%;
  }
}

.image-container {
  text-align: center;
}

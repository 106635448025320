//++++++++++++++++++++++++++++++++
//+ DNA hint text
//++++++++++++++++++++++++++++++++
.dna-input-helper-text {
	font-family: @font-base;
	margin:@space-xs 0 0;
	font-size:@form-font-size-s;
	line-height:1.2;
}

.dna-input-help {
	&:extend(.dna-input-helper-text);
	color:@form-font-color-help;
	
	> a {
		font-weight:@font-weight-normal;
	}

	@media @mq-l{
		margin:@space-s 0 0;
	}

	*:disabled + & {
		color:@form-font-color-disabled;
	}
}

.dna-input-validation {
	&:extend(.dna-input-helper-text);
	color:@form-font-color-error;
	
	@media @mq-l{
		margin:@space-s 0 0;
	}
}

.dna-input-validation-error {
	&:extend(.dna-input-helper-text);
	margin:0 0 @space-xs;
	
	@media @mq-l{
		margin:0 0 @space-s;
	}
}

.dna-input-nudge {
	&:extend(.dna-input-helper-text);
	color:@form-font-color-nudge;
	
	@media @mq-l{
		margin:@space-s 0 0;
	}
}
//-------------------------------------------------
// totaliser mixins
//-------------------------------------------------

// set totaliser container size
.container-size-settings(@bp-s-size) {
	width: @bp-s-size;
	height: @bp-s-size;

	.dna-totaliser-inner {
		height: @bp-s-size;
		line-height: @bp-s-size;
	}
	.dna-totaliser-mask {
		width: @bp-s-size + 90;
		height: @bp-s-size + 90;
	}

	.dna-totaliser-background {
		width: @bp-s-size - 4;
		height: @bp-s-size - 4;
	}
}

.font-size-settings(@baseFont) {
	.dna-totaliser-inner {
		font-size: @baseFont;

		.dna-totaliser-value {
			font-size: 1em;
		}
		.dna-totaliser-percentage {
			font-size: 0.5em;
		}
	}
}

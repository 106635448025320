@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  touch-action: none;

  canvas {
    cursor: move;
  }
}

.v-align-middle {
  vertical-align: middle;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include small {
    display: block;
  }
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.avatar {
  border: 2px solid $color-white;
  border-radius: 50%;
  flex-shrink: 0;
  height: 50px;
  width: 50px;
  object-position: center;
  object-fit: cover;

  @include xlarge {
    height: 64px;
    width: 64px;
  }
}

.donate {
  display: none;
  margin: 12px auto 0;
  max-width: 280px;
  padding: 0 30px;

  @include medium {
    display: block;
  }
}

.become-supporter-container {
  display: none;

  @include xlarge {
    display: flex;
  }
}

.become-supporter-header {
  font-size: 1.2em;
  font-weight: 400;
}

.become-supporter-paragraph {
  font-size: 15px;
}

.become-supporter-text {
  line-height: 32px;
  margin-left: 20px;
  width: 100%;
}

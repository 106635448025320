@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  color: $color-nobel;
  display: inline-block;
  margin: 0 auto 16px;
  font-size: 0.75em;
  text-align: left;
  width: 100%;

  @include small {
    font-size: 0.875em;
    margin-left: 10px;
  }

  @include large {
    margin-left: 13px;
    width: 85%;
  }

  @include xlarge {
    margin-left: 110px;
    text-align: left;
  }
}

.meta-item {
  display: block;
  margin-bottom: 5px;

  @include large {
    display: inline-block;
    margin-bottom: 0;
  }
}

.heading {
  display: inline-block;
  padding: 0 8px;
  vertical-align: middle;
  line-height: 14px;
}

.value {
  display: inline-block;
  margin-right: 10px;
}

.icon {
  svg {
    width: 16px;
    height: 16px;
    fill: $color-nobel;
  }
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.logo-wrapper {
  border-radius: 2px;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  position: relative;
  margin: 0 auto;

  img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  @include medium {
    min-width: 96px;
    min-height: 96px;
    max-width: 96px;
    max-height: 96px;
  }
}

.tick {
  bottom: -6px;
  right: -10px;
}

.small-tick {
  bottom: -3px;
  right: -8px;
}

.opacity {
  opacity: 0.6;
}

.charity-info {
  flex-direction: column;

  @include medium {
    flex-direction: row;
  }
}

.jg-tooltip-desktop {
  max-height: auto;
  max-width: 400px;
  min-width: 200px;
  background-color: $color-white;
  border: 1px solid $color-very-light-grey;
  border-radius: 3px;
  z-index: 1;
  padding: 12px;
  position: relative;
  align-self: flex-start;

  &:after,
  &:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    z-index: 2;
  }

  &:after {
    border-right-color: $color-white;
    border-width: 10px;
    margin-top: -10px;
  }

  &:before {
    border-right-color: $color-very-light-grey;
    border-width: 12px;
    margin-top: -12px;
  }

  @include large {
    min-width: 300px;
  }
}

.jg-tooltip-mobile {
  height: 100vh;
  width: 100%;
  z-index: 100;
  background-color: $color-white-smoke;
  text-align: center;

  .tooltip-modal-button {
    position: absolute;
    bottom: 0;
  }
}

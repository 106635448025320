@import '../icons';

.icon-tick-blue() {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+Ymx1ZSB0aWNrPC90aXRsZT48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC44NDMpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik01LjkzNiAxMy42OWwzLjAyNSAzLjA4MyA5LjU0NC05LjcyMyIgc3Ryb2tlPSIjMUQ2N0JGIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L2c+PC9zdmc+DQo=');
}
.icon-tick-blue-faded() {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+Ymx1ZSB0aWNrPC90aXRsZT48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC44NDMpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9wYWNpdHk9IjAuMyI+PHBhdGggZD0iTTUuOTM2IDEzLjY5bDMuMDI1IDMuMDgzIDkuNTQ0LTkuNzIzIiBzdHJva2U9IiMxRDY3QkYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIvPjwvZz48L3N2Zz4NCg==');
}
.icon-tick-green() {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+Ymx1ZSB0aWNrPC90aXRsZT48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC44NDMpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik01LjkzNiAxMy42OWwzLjAyNSAzLjA4MyA5LjU0NC05LjcyMyIgc3Ryb2tlPSIjMTVDQzVFIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L2c+PC9zdmc+DQo=');
}
.icon-tick-grey() {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+Z3JleSB0aWNrPC90aXRsZT48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC44NDMpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik01LjkzNiAxMy42OWwzLjAyNSAzLjA4MyA5LjU0NC05LjcyMyIgc3Ryb2tlPSIjQ0NDQ0NDIiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48L2c+PC9zdmc+DQo=');
}
.icon-tick-sm() {
	width:24px;
	height:25px;
}
.dna-icon-tick-sm-blue {
	.dna-icon;
	.icon-tick-blue;
	.icon-tick-sm;
}
.dna-icon-tick-sm-blue-faded {
	.dna-icon;
	.icon-tick-blue-faded;
	.icon-tick-sm;
}
.dna-icon-tick-sm-green {
    .dna-icon;
    .icon-tick-green;
    .icon-tick-sm;
}
.dna-icon-tick-sm-grey {
    .dna-icon;
    .icon-tick-grey;
    .icon-tick-sm;
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';
@import './SocialShareButton.scss';

.qr {
  @extend .share-button;

  background-image: url('./images/mobile-qr.svg');
  margin-bottom: 0px;

  img {
    display: none;
  }
}

.share-button-container {
  @include large {
    display: none;
  }
}

// @import '~@justgiving/justgiving-bundle-global-css/dist/global.css';

// TODO HACK: This is a temporary fix to get the DNA components working with webpack 4 and 5
//
// @import "~@justgiving/dna-core/components/global/global";
//
@import './dna-button/components/button-default/button-default';
@import './dna-button/components/button-social/button-social';

@import './dna-form/components/form/form';
@import './dna-form/components/input/input';
@import './dna-form/components/select/select';
@import './dna-form/components/textarea/textarea';
@import './dna-form/components/input-select-group/input-select-group';
@import './dna-form/components/character-count/character-count';
@import './dna-form/components/radio/radio';
@import './dna-core/components/variables/global-variables';
@import './dna-core/components/variables/colour-definitions';
@import './dna-core/components/variables/components/typography';
@import './dna-progress/components/totaliser/totaliser';
@import './dna-typography/components/typography/typography';
@import './dna-card/components/relatedStory/relatedStory.less';

@import '~react-datepicker/dist/react-datepicker.css';

// Lovely hacks for DNA bugs and JG Chrome. To be removed.
a,
a:link {
  color: #1667d9;
}

a.dna-button {
  line-height: 42px;
  text-decoration: none;

  @media @mq-l {
    line-height: 48px;
  }
}

a.dna-button-brand:link {
  color: @white;
}

.dna-button-brand {
  background: #1667d9;
  background-color: #1667d9;
  border: solid #1667d9 1px;

  &:active {
    background: #002266 !important;
  }
  &:hover {
    border-color: #002266 !important;
  }
}

.dna-text-brand {
  color: #7a04dd;
}
#7a04dd .dna-button-disabled {
  border: 0;
  background-color: @very-light-grey;
  color: @white;
  pointer-events: none;
}

.update-text-wrapper {
  .dna-character-count {
    background-color: transparent;
    border: 0 !important;
    bottom: -36px;
    left: auto;
    padding-right: 0;
    right: 0;
    width: 100px;

    @media @mq-m {
      bottom: -42px;
    }
  }
}

.update-submit-button {
  border: 0;
  font-size: 15px;
  font-weight: @normal;
  height: 40px;

  &:disabled {
    background-color: @very-light-grey;
  }

  @media @mq-m {
    font-size: 17px;
  }
}

.page-info-totaliser {
  @media @mq-xl {
    margin: 0 auto;
  }

  .dna-totaliser-percentage {
    margin-left: 5px;
  }
}

body.no-scroll {
  overflow: hidden;
}

body.body-fixed {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.ReactModal__Body--open {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;

  @media @mq-l {
    position: static;
  }
}

.mobile-search-container {
  display: none;
}

// HACK: DNA-select fallbacks to the native HTML select when it detects that
// touch events are supported, presumably to better support mobile devices where
// the native HTML element is easier to use for lists with a lot of items.
// Unfortunately, not all devices supporting touch events are "mobile", and
// some of them don't implement the "nice" native element.
// This hack allows to selectively disable the fallback to the native element
// in cases where the native element looks bad on non-mobile devices with
// touch event support.
.disable-mobile-native {
  .dna-select-options {
    &:extend(.dna-input all);
    display: block;
    visibility: hidden;
    padding: 0;
    position: absolute;
    top: ~'calc(100% - 1px)';
    width: ~'calc(100% + 2px)';
    height: auto;
    z-index: 1;
    color: inherit;
    border-color: @form-border-color-focused;
  }

  .dna-select-open {
    .dna-select-options {
      visibility: visible;
    }
  }

  .js-dna-select {
    left: -999em;
  }
}

// HACK: if dna-select has a lot of options it can push the footer down
ul.dna-select-options {
  max-height: 263px;
  overflow-y: scroll;
}

// HACK: only the character count part of the DNA textarea displays a
// blue border when hovered, so this applies the same border all around
.dna-textarea .js-dna-character-count {
  &:hover {
    border-color: #1667d9;
  }
}

.dna-input-error {
  border-color: @valencia !important;
}

.dna-text-brand-m {
  color: #7a04dd;
}

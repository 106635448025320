//-------------------------------------------------
// totaliser base
// - base extends
//-------------------------------------------------

.dna-totaliser {
	color: @totaliser-font-colour;
	font-weight: 400;
	position: relative;
	overflow: hidden;
	text-align: center;

	.dna-totaliser-background {
		position: absolute;
		background: @totaliser-background-colour;
		top: 2px;
		left: 2px;
	}

	// totaliser value container
	.dna-totaliser-inner {
		visibility: hidden;
		position: absolute;
		top: 0;
		width: 100%;
		transition: all 0.5s ease;
	}
	.dna-totaliser-percentage {
		color: @totaliser-font-colour;
		font-weight: 400;
		margin-left: 4px;
	}

	// totaliser level
	.dna-totaliser-level-wrapper {
		position: absolute;
	}
	.dna-totaliser-level {
		visibility: hidden;
		position: absolute;
		will-change: left, bottom;
	}
	.dna-totaliser-wave {
		position: relative;
		fill: @totaliser-level-colour;
	}
	.dna-totaliser-wave-path {
		fill: @totaliser-level-colour;
	}

	// totaliser mask
	.dna-totaliser-mask {
		left: -45px;
		top: -45px;
		position: absolute;
		border-radius: 50%;
		border: 46px solid @totaliser-mask-colour;
	}

	// totaliser ring
	.dna-totaliser-ring {
		position: relative;
		display: block;
		border-radius: 50%;
		height: 100%;

		span {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}
	}
	&.is-active {
		// totaliser value container
		.dna-totaliser-inner {
			visibility: visible;
		}

		// totaliser level
		.dna-totaliser-level {
			visibility: visible;
			left: 0!important;
			bottom: 0!important;
		}
	}
}

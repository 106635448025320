//++++++++++++++++++++++++++++++++
//+ Inline form buttons
//++++++++++++++++++++++++++++++++

// These buttons are as normal but have a smaller min height
// so they are the same height as DNA form elements at each break-point.
// They are intended for use when set inline (next to) a form component

.dna-button-form-inline {
    min-height: @button-small-height!important;
    font-size: @button-font-size-s!important;
    line-height: @button-line-height-s!important;

    @media @mq-l {
        min-height: @button-default-height!important;
        font-size:@button-font-size-m!important;
        line-height: @button-line-height-m!important;
    }
}

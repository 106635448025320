@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  overflow: hidden;
  padding-bottom: 16px;
  background-color: $color-white;

  @include small {
    padding-bottom: 0px;
  }

  @include large {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    border-left: 1px solid $color-very-light-grey;
  }

  @include xlarge {
    background-color: $color-white-smoke;
    border-radius: 2px;
    border: 0;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 0;
  }
}

.owner-meta-container {
  position: relative;
  order: 1;
  width: 100%;

  @include xlarge {
    order: 3;
    width: 65%;
  }
}

.cover-image-container {
  position: relative;
  width: 100%;
  background-color: $color-white;

  @include xlarge {
    order: 1;
    width: 65%;
    border-left: 1px solid $color-very-light-grey;
    border-bottom: 1px solid $color-very-light-grey;
  }
}

.progress-donate-container {
  display: flex;
  order: 2;
  width: 100%;
  background-color: $color-white;
  align-items: center;
  justify-content: space-between;

  @include large {
    margin-left: 3px;
    margin-top: 10px;
  }

  @include xlarge {
    border-bottom: 1px solid $color-very-light-grey;
    border-right: 1px solid $color-very-light-grey;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    padding-top: 20px;
    order: 2;
    width: 35%;
  }
}

.owner {
  width: 100%;
  order: 4;

  @include xlarge {
    margin-bottom: 40px;
    order: 2;
  }
}

.crowdfunding-title-link {
  text-decoration: none;
}

.title-wrapper {
  width: 100%;
  text-align: center;
  order: 3;
  margin-bottom: 8px;
  padding: 0 24px;

  @include xlarge {
    padding: 0 24px;
    margin-bottom: 40px;
  }
}

.title {
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.2;

  @include xlarge {
    font-size: 1.94em;
    max-height: 220px;
    overflow: hidden;
  }
}

.countdown {
  width: 100%;
  order: 2;

  @include xlarge {
    order: 3;
  }
}

.meta {
  width: 100%;
  order: 5;

  @include xlarge {
    margin-top: -40px;
  }
}

.progress {
  width: 100%;
  order: 6;
}

.actions {
  display: none;
  max-width: 330px;
  width: 100%;

  @include large {
    display: block;
    order: 2;
  }

  @include xlarge {
    max-width: none;
  }
}

.share-unavailable {
  display: block;
  margin-top: 20px;
  font-weight: 300;
  text-align: center;
}

.recurring-donations {
  display: flex;
  justify-content: center;
  order: 3;

  @include large {
    display: none;
  }
}

.bottom-right {
  order: 4;

  @include large {
    margin-left: 8px;
  }

  @include xlarge {
    margin-left: 0;
    width: 35%;
  }
}

button.link {
  color: $color-brand-primary;
}

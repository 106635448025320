@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.editor {
  cursor: text;
  min-height: 150px;

  p {
    margin-bottom: $global-spacing-unit-ml;
  }

  h2 {
    margin-bottom: $global-spacing-unit-ms;
  }
}

.toolbar {
  width: 100%;
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
  position: relative;

  @include large {
    justify-content: flex-start;
  }
}

.toolbar-tooltip {
  position: absolute;
  background-color: $color-white-smoke;
  border: 1px solid $color-very-light-grey;
  border-radius: 3px;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  bottom: 150%;

  p {
    margin-bottom: 0px;
  }

  .grey-cross {
    width: 16px;
    height: 16px;
    fill: $color-very-light-grey;
    margin-left: 14px;

    svg {
      display: block;
    }
  }

  &:after,
  &:before {
    top: 100%;
    left: 34%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: $color-white-smoke;
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: $color-very-light-grey;
    border-width: 12px;
    margin-left: -12px;
  }
}

.toolbar-icon {
  fill: $color-nobel;
  stroke: $color-nobel;
}

.toolbar-icon:hover {
  @include medium {
    fill: $color-brand-primary;
    stroke: $color-brand-primary;
  }
}

.toolbar-icon.is-active {
  fill: $color-brand-primary;
  stroke: $color-brand-primary;
}

.toolbar-icon-disabled {
  fill: $color-gainsboro;
  stroke: $color-gainsboro;
}

.toolbar-icon-tooltip {
  visibility: hidden;
  position: absolute;
  width: 110px;
  padding: 8px;
  text-align: center;
  background-color: $color-white;
  border: 1px solid $color-very-light-grey;
  border-radius: 3px;
  bottom: 200%;
  left: 50%;
  margin-left: -55px;
  z-index: 1;

  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: $color-white;
    border-width: 10px;
    margin-left: -10px;
  }
  &:before {
    border-top-color: $color-very-light-grey;
    border-width: 12px;
    margin-left: -12px;
  }
}

.button-inner-wrapper:hover .toolbar-icon-tooltip {
  @include medium {
    visibility: visible;
  }
}

.button-wrapper {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 82px;
}

.button-inner-wrapper {
  position: relative;
  display: inline-block;
}

.quote {
  position: relative;
  padding: 8px 30px 0px 30px;

  p {
    color: $color-brand-primary;
    font-size: 1rem;
    line-height: 1.5;
  }

  .quote-marks {
    position: absolute;
    top: 0;
    left: 0;
    fill: $color-brand-primary;
  }

  @include large {
    p {
      font-size: 1.125rem;
      line-height: 1.33333;
    }
  }
}

.quote-container {
  width: 80%;
  margin: 0 auto;
  margin-bottom: $global-spacing-unit-ml;
}

.tooltip {
  position: absolute;
  transition: opacity 0.75s;
  z-index: 100000;
}

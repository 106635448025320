//++++++++++++++++++++++++++++++++
//+ DNA button skins
//++++++++++++++++++++++++++++++++

// Default button skin
.default-button-skin(@color;
		@background-color;
		@background-color-hover;
		@background-color-active;
		@background-color-disabled;
		@button-border-color: @background-color;
		@button-border-color-disabled: @background-color-disabled) {
	background: @background-color;
	color: @color;
	border: solid @button-border-color 1px;

    // Button group overrides
	.dna-button-group &:active {
        color: @color!important;
        border: solid @background-color-active 1px!important;
    }

	.no-touchevents &:hover,
	.no-touch &:hover {
		background: rgb(255, 255, 255);
		background: @background-color-hover;
	}
	&:active {
		background: rgb(255, 255, 255);
		background: @background-color-active!important;
    }
	&:visited {
		color: @color;
	}
	&.is-disabled,
	.no-touchevents &.is-disabled:hover,
	.no-touch &.is-disabled:hover {
		background: @background-color-disabled!important;
		color: @color!important;
		border-color: @button-border-color-disabled!important;
	}
	// Integration with dna-button-group
	:not(.is-active) &:hover {
		color: @color;
		border-color: @button-border-color;
	}
}

// Ghost button skin decorator
.ghost-button-skin(@border-radius;
			@button-border-color;
			@button-border-color-fallback;
			@button-border-color-disabled;
			@button-border-color-disabled-fallback) {
	border-radius: @border-radius;
	border: solid @button-border-color 1px;

    // Button group overrides
    .dna-button-group &:active {
        border: solid @button-border-color 1px!important;
    }

	&:active {
		border-color: @button-border-color-fallback;
		border-color: @button-border-color!important;
	}
	&.is-disabled,
	.no-touchevents &.is-disabled:hover,
	.no-touch &.is-disabled:hover {
		border-color: @button-border-color-disabled-fallback;
		border-color: @button-border-color-disabled!important;
	}
	&:hover {
		position: relative;
	}
	// Integration with dna-button-group
	:not(.is-active) &:hover {
		border-color: @button-border-color-fallback;
		border-color: @button-border-color;
		border-right-width: 1px;
	}
}

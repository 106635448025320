//++++++++++++++++++++++++++++++++
//+ Button size variants
//++++++++++++++++++++++++++++++++

.dna-button-small {
	min-height: @button-small-height;
	font-size: @button-font-size-xs;
	line-height: @button-line-height-xs;

	@media @mq-l {
		min-height: @button-default-height;
		font-size: @button-font-size-s;
		line-height: @button-line-height-s;
	}
}

.dna-button-small-static {
	min-height: @button-small-height!important;
	font-size: @button-font-size-xs!important;
	line-height: @button-line-height-xs!important;
}
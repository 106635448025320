@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

@mixin createArrow() {
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

@mixin innerArrow(
  $borderDirection,
  $marginDirection,
  $borderColour,
  $arrowWidth: 10
) {
  border-color: transparent;
  border-width: #{$arrowWidth}px;
  border-#{$borderDirection}-color: $borderColour;
  margin-#{$marginDirection}: -#{$arrowWidth}px;
}

// Bottom
.supporter-message-box-bottom:after,
.supporter-message-box-bottom:before {
  @include createArrow();
  top: 100%;
  left: 50%;
}

.supporter-message-box-bottom-left:after,
.supporter-message-box-bottom-left:before {
  @include createArrow();
  top: 100%;
  left: 25px;
}

.bottom-right {
  @include createArrow();
  top: 100%;
}

.supporter-message-box-bottom-right:after {
  @extend .bottom-right;
  right: 27px;
}

.supporter-message-box-bottom-right:before {
  @extend .bottom-right;
  right: 25px;
}

.supporter-message-box-bottom:after,
.supporter-message-box-bottom-left:after,
.supporter-message-box-bottom-right:after {
  @include innerArrow(top, left, $color-white);
}

.supporter-message-box-bottom:before,
.supporter-message-box-bottom-left:before,
.supporter-message-box-bottom-right:before {
  @include innerArrow(top, left, $color-very-light-grey, 12);
}

// Left
.supporter-message-box-left:after,
.supporter-message-box-left:before {
  @include createArrow();
  top: 50%;
  right: 100%;
}

.supporter-message-box-left-top:after,
.supporter-message-box-left-top:before {
  @include createArrow();
  top: 25px;
  right: 100%;
}

.left-bottom {
  @include createArrow();
  right: 100%;
}

.supporter-message-box-left-bottom:after {
  @extend .left-bottom;
  bottom: 25px;
}

.supporter-message-box-left-bottom:before {
  @extend .left-bottom;
  bottom: 24px;
}

.supporter-message-box-left:after,
.supporter-message-box-left-bottom:after,
.supporter-message-box-left-top:after {
  @include innerArrow(right, top, $color-white);
}

.supporter-message-box-left:before,
.supporter-message-box-left-bottom:before,
.supporter-message-box-left-top:before {
  @include innerArrow(right, top, $color-very-light-grey, 12);
}

// Right
.supporter-message-box-right:after,
.supporter-message-box-right:before {
  @include createArrow();
  top: 50%;
  left: 100%;
}

.right-bottom {
  @include createArrow();
  left: 100%;
}

.supporter-message-box-right-bottom:before {
  @extend .right-bottom;
  bottom: 25px;
}

.supporter-message-box-right-bottom:after {
  @extend .right-bottom;
  bottom: 26px;
}

.supporter-message-box-right-top:after,
.supporter-message-box-right-top:before {
  @include createArrow();
  top: 25px;
  left: 100%;
}

.supporter-message-box-right:after,
.supporter-message-box-right-bottom:after,
.supporter-message-box-right-top:after {
  @include innerArrow(left, top, $color-white);
}

.supporter-message-box-right:before,
.supporter-message-box-right-bottom:before,
.supporter-message-box-right-top:before {
  @include innerArrow(left, top, $color-very-light-grey, 12);
}

// Top
.supporter-message-box-top:after,
.supporter-message-box-top:before {
  @include createArrow();
  left: 50%;
  bottom: 100%;
}

.supporter-message-box-top-left:after,
.supporter-message-box-top-left:before {
  @include createArrow();
  left: 25px;
  bottom: 100%;
}

.top-right {
  @include createArrow();
  bottom: 100%;
}

.supporter-message-box-top-right:after {
  @extend .top-right;
  right: 26px;
}

.supporter-message-box-top-right:before {
  @extend .top-right;
  right: 25px;
}

.supporter-message-box-top:after,
.supporter-message-box-top-left:after,
.supporter-message-box-top-right:after {
  @include innerArrow(bottom, left, $color-white);
}

.supporter-message-box-top:before,
.supporter-message-box-top-left:before,
.supporter-message-box-top-right:before {
  @include innerArrow(bottom, left, $color-very-light-grey, 12);
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';
@import './SocialShareButton.scss';

.email {
  @extend .share-button;

  background-image: url('./images/mobile-email.svg');

  @include large {
    background-image: url('./images/round-email.svg');
  }
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  @include large {
    border: 1px solid $color-very-light-grey;
  }
}

.image {
  width: 120px;
  margin: 0 auto;
}

@import '../../../dna-core/components/global/global';
@import '../../../dna-core/components/variables/components/form';

//++++++++++++++++++++++++++++++++
//+ DNA input-prefix
//++++++++++++++++++++++++++++++++

.dna-input-prefix {
	font-family: @font-base;
	position: relative;
    display: block;

	.dna-input-prefix-token {
		box-sizing: content-box;
		position: absolute;
		z-index: 1;
		display: block;
		left: @input-padding;
		top: 0;
		font-size: @form-font-size-s;
		line-height: @input-height-default;
		font-weight: @font-weight-light;
		padding-right: @input-padding;

        @media @mq-l{
            font-size:@form-font-size-m;
            line-height:@input-height-m;
        }
	}
}

//+++++++++++++++++++++++++++++++++++
//+ DNA input-prefix size variants
//+++++++++++++++++++++++++++++++++++

.dna-input-prefix-token {
	.dna-input-medium + & {
        font-size:@form-font-size-m;
        line-height:@input-height-m;
	}
	.dna-input-large + & {
        font-size:@form-font-size-l;
        line-height:@input-height-l;
	}
}
@import '~@justgiving/styles/src/styles.scss';
@import './shame.scss';
@import '~@justgiving/loading/src/Loading.scss';
@import '~@justgiving/select/src/Select.scss';
@import '~@justgiving/totaliser/src/Totaliser.scss';
@import '~@justgiving/totaliser/src/themes/Snowglobe/Snowglobe.scss';
@import '~@justgiving/date-picker/src/SingleDatePicker.scss';

@import url('https://fonts.googleapis.com/css?family=Montserrat:600');

// HACK: the ui-toolkit has a default of scroll which lets
// the body scroll when a modal is open
html {
  overflow-y: visible;
}

// REMOVE WHEN FIXED IN UI TOOLKIT
// HACK: the ui-toolkit has a default margin applied to them
// this obviously breaks the layout of our app, an issue
// has been filed https://source.justgiving.com/JustGiving/JG.FrontEnd.Toolkit/issues/109
// till this is fixed this removes the margin
dd,
ol,
ul {
  margin-left: 0;
}

// toolkit overides the dna-select font settings
// react component is in the works for the select element
.dna-select {
  line-height: 14px;
  font-weight: $global-font-weight;

  @include large {
    line-height: 17px;
  }
}

.fixed-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

//++++++++++++++++++++++++++++++++
//+ DNA password input
//++++++++++++++++++++++++++++++++

.dna-input-password{
	padding-right: 60px;
}

// Below is created by JS if using vanilla module
// (will need to create manually if using framework - see demo)
.dna-input-password-wrap{
	position:relative;
}
.dna-input-password-button{
	position:absolute;
	right:1px;
	border:0;
	background:transparent;
	font-size:@form-font-size-s;
	color: @form-link-color;
	line-height:@input-height-default;
	width:59px;
	padding:0;

	&:focus{
		outline:none;
	}

	@media @mq-l{
		line-height: @input-height-m;
	}
}
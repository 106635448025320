@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.prompt {
  z-index: 1;
  background-color: $color-fog;
  background-image: url('../images/confetti-large.svg');
  background-position: top;
  background-repeat: repeat;
  background-size: auto;
  padding-top: $global-spacing-unit-md;

  @include large {
    background-position: center;
    padding-top: $global-spacing-unit-lg;
  }
}

.people {
  @media screen and (min-width: px2em(breakpoint(medium))) {
    background-image: url('../images/people.svg');
    background-position: left bottom;
    background-position-x: 2%;
    background-repeat: no-repeat;
    background-size: 20%;
  }

  @media screen and (min-width: px2em(breakpoint(xlarge))) {
    background-size: contain;
  }
}

.col-info {
  padding-bottom: $global-spacing-unit-md;

  @include large {
    padding-bottom: $global-spacing-unit-lg;
  }
}

.messages {
  padding-right: $global-spacing-unit-sm;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;

  @include medium {
    justify-content: initial;
    align-items: initial;
    text-align: initial;
  }

  @media screen and (min-width: px2em(breakpoint(xlarge))) {
    padding-right: $global-spacing-unit-lg;
  }
}

.col-share {
  padding-top: $global-spacing-unit-sm;

  @include medium {
    padding-top: 0;
  }
}

.col-btn-share {
  white-space: nowrap;
  display: inline-table;
  font-weight: 400;
}

.btn-share {
  display: none;

  @include medium {
    display: inherit;
  }
}

.link-share {
  text-align: center;
  display: initial;

  @include medium {
    display: none;
  }
}

.grey-cross {
  display: none;

  @include medium {
    display: initial;
    background-image: url('../images/MOB-close-icon-grey-active.svg');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    width: 13px;
    height: 13px;
    right: 16px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    border: none;
    background-color: transparent;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      background-image: url('../images/MOB-close-icon-grey-hover.svg');
    }
  }

  @include large {
    background-image: url('../images/DT-close-icon-grey-active.svg');
    width: 20px;
    height: 20px;
    right: 20px;
    top: 20px;

    &:hover {
      background-image: url('../images/DT-close-icon-grey-hover.svg');
    }
  }
}

.prompt-text {
  font-weight: lighter;
}

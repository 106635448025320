@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.avatar {
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  object-position: center;
  object-fit: cover;
}

.xxsmall {
  width: 26px;
  height: 26px;
}

.xsmall {
  width: 40px;
  height: 40px;
}

.small {
  width: 50px;
  height: 50px;
}

.medium {
  width: 50px;
  height: 50px;

  @include large {
    width: 64px;
    height: 64px;
  }
}

.large {
  width: 80px;
  height: 80px;
}

.xlarge {
  width: 96px;
  height: 96px;
}

.share-prompt-profile {
  width: 280px;
  height: 280px;
}

.share-prompt-profile-mobile {
  width: 136px;
  height: 136px;
}

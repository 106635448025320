@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container-funded {
  background-image: url('../images/MOBILE-bkgrd-hearts-280X380.svg');
  background-size: 98%;
  background-repeat: no-repeat;
  background-position: center;

  @include medium {
    background-image: url('../images/iPAD-bkgrd-hearts-300x791.svg');
  }

  @include large {
    background-image: url('../images/iPAD-bkgrd-hearts-300x1024.svg');
  }

  @include xlarge {
    background-image: url('../images/DT-bkgrd-hearts-300x1600.svg');
  }
}

.breakline {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;

  &:before {
    content: '';
    height: 1px;
    width: 60px;
    display: inline-block;
    background-position: center center;
    border-bottom: 1px $color-very-light-grey solid;
    position: absolute;
    top: 50%;
    left: 20px;
  }

  &:after {
    content: '';
    height: 1px;
    width: 60px;
    display: inline-block;
    background-position: center center;
    border-bottom: 1px $color-very-light-grey solid;
    position: absolute;
    top: 50%;
    right: 20px;
  }
}

.timer {
  padding-top: 3px;
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11000;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

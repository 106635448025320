@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  align-items: center;
  border-top: 1px solid $color-very-light-grey;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  text-align: center;
  width: 100%;

  @include large {
    flex-wrap: nowrap;
    text-align: left;
  }
}

.text {
  align-self: center;
  flex: 4 auto;
  font-size: 1em;
  margin: 0 10px 10px 0;
  width: 100%;

  @include large {
    margin-bottom: 0px;
    width: 70%;
  }
}

.facebook-container {
  align-self: center;
  display: block;
  margin: 0 auto;
  width: 90%;

  @include large {
    flex: auto;
    width: 45%;
  }
}

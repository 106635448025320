@import '../Page/PageHeader/PageHeader.scss';
@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  @include small {
    border-top: 1px solid $color-very-light-grey;
    background: #f5f5f5;
  }
  @include medium {
    border-top: 0px;
  }
}

.body {
  background-color: $color-white;
  width: 100%;
  display: block;
  @include small {
    border: 1px solid $color-very-light-grey;
  }
  @include medium {
    padding-top: 0px;
  }
}

.image {
  width: 120px;
  margin: 0 auto;
}

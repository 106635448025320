@import '../../../dna-core/components/global/global';
@import '../../../dna-core/components/variables/components/form';

//++++++++++++++++++++++++++++++++
//+ DNA input
//++++++++++++++++++++++++++++++++
.dna-input {
	font-family: @font-base;
	background: @form-component-background-color-default;
	display:inline-block;
	height: @input-height-default;
	width:100%;
	padding:@input-padding;
	border:1px solid @form-border-color;
	border-radius:@form-border-radius;
	font-size:@form-font-size-s;
	color:@form-font-color;
	transition:color @form-transition-duration, border-color 0.2s;
	background-color: @form-component-background-color-default;

	@media @mq-l{
		height: @input-height-m;
		font-size: @form-font-size-m;
	}

	.no-touchevents &:hover{
		border-color:@form-border-color-focused;
	}

	&:focus{
		outline:none;
		border-color:@form-border-color-focused;
	}

	.no-touchevents &:disabled,
	.touchevents &:disabled{
		border-color:@form-border-color-disabled;
		color:@form-font-color-disabled;
	}

	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&:-moz-placeholder,
	&:-ms-input-placeholder {
		color: @form-font-color-placeholder;
	}

	&:disabled::-webkit-input-placeholder,
	&:disabled::-moz-placeholder,
	&:disabled:-moz-placeholder,
	&:disabled:-ms-input-placeholder {
		color: @form-font-color-disabled;
	}
}

//++++++++++++++++++++++++++++++++
//+ DNA input size variants
//++++++++++++++++++++++++++++++++
.dna-input-medium {
	height: @input-height-m;
	font-size: @form-font-size-m;
}

.dna-input-large {
	height: @input-height-l;
	font-size: @form-font-size-l;
	font-weight: @font-weight-light;
}

//++++++++++++++++++++++++++++++++
//+ DNA for input error states
//++++++++++++++++++++++++++++++++

.no-touchevents .dna-input-error,
.touchevents .dna-input-error {
	border-color:@form-border-color-error!important; // Remove important if error strategy is to clear errors on focus
}

@import 'decorators/input-password';
@import 'decorators/input-number';
@import 'decorators/input-credit-card';

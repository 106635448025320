@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.reminder {
  width: 100%;
  height: 45px;
  margin: 8px 0;
  border-radius: 4px;
  padding: 10px;
  font-weight: 500;
  font-size: 18px;
  background-color: transparent;
  border: 1px solid #999;
  position: relative;

  img {
    position: absolute;
    width: 20px;
    left: 10px;
    top: 10px;
  }

  @include large {
    width: 300px;
  }
}

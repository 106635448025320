@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.link {
  @include large {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.footer {
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.avatar {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  cursor: pointer;

  @include medium {
    display: none;
  }
}

.avatar-container {
  display: flex;
  text-align: left;
}

.placeholder {
  align-self: center;
}

.action-container {
  display: none;

  @include medium {
    display: block;
  }
}

.action {
  @include medium {
    width: 150px;
  }
}

//++++++++++++++++++++++++++++++++
//+ Base collapse decorator
//++++++++++++++++++++++++++++++++
.dna-button-collapse {
	@media @mq-to-m {
		text-indent: -9999px!important;
		width: @button-collapse-width!important;
		border-radius: 50%!important;
		padding: 0!important;
	}
}
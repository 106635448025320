// Email icons
.icon-email-grey() {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+aWNvbi1lbWFpbDwvdGl0bGU+PHBhdGggZD0iTTI0LjkgNi4xYzAtMS4yLTEtMi4xLTIuMS0yLjFIMy4xQzEuOSA0IDEgNSAxIDYuMVYxOWMwIDEuMiAxIDIuMSAyLjEgMi4xaDE5LjdjMS4yIDAgMi4xLTEgMi4xLTIuMVY2LjF6bS0yMS44IDBoMTkuN3YuMmwtOS45IDYuMi05LjgtNi4ydi0uMnptMCAyLjhsNC44IDMtNC44IDUuOFY4Ljl6bTIgMTAuMWw0LjgtNS45IDMuMyAyIDMuMy0yIDQuOCA1LjlINS4xem0xMy4yLTcuMWw0LjgtM3Y4LjhsLTQuOC01Ljh6IiBmaWxsPSIjOTk5IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=');
}

.icon-email-sm() {
	width:25px;
	height:22px;
}

.dna-icon-email-sm-grey {
	.dna-icon;
	.icon-email-sm;
	.icon-email-grey;
}

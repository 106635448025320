@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.email {
  border: 1px $color-nobel solid;
  background-color: $color-nobel;
  width: 100%;
  border-radius: 4px;
  text-align: center;
  background-image: url('../SocialShare/images/icon-email-white.svg');
  background-repeat: no-repeat;
  background-position: 14px 16px;
  background-size: 22px;
  color: white;

  &:hover {
    cursor: pointer;
    background-color: $color-empress;
    border: 1px $color-empress solid;
  }
}

// Cross icons

// COLOUR
.icon-cross-red() {
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PHN2ZyB3aWR0aD0iMjZweCIgaGVpZ2h0PSIyNnB4IiB2aWV3Qm94PSIwIDAgMjYgMjYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiPiAgICAgICAgPHRpdGxlPmljb24tY2xvc2UtbWVkLWdyZXk8L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBza2V0Y2g6dHlwZT0iTVNQYWdlIj4gICAgICAgIDxnIGlkPSJJY29ucy1tZWQtKDI2cHgpIiBza2V0Y2g6dHlwZT0iTVNBcnRib2FyZEdyb3VwIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzUwLjAwMDAwMCwgLTgwLjAwMDAwMCkiIHN0cm9rZT0iI0Y1NTY1OCIgc3Ryb2tlLXdpZHRoPSIwLjUiIGZpbGw9IiNGNTU2NTgiPiAgICAgICAgICAgIDxnIGlkPSJpY29uLS8tY2xvc2UtLy1tZWQtLy1ncmV5LWNvcHkiIHNrZXRjaDp0eXBlPSJNU0xheWVyR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDM1MC4wMDAwMDAsIDgwLjAwMDAwMCkiPiAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjEuNDUsMy4xNSBDMjEuMjUsMi45NSAyMC44NSwyLjk1IDIwLjY1LDMuMTUgTDEyLjk1LDEwLjk1IEw1LjI1LDMuMjUgQzUuMDUsMy4wNSA0LjY1LDMuMDUgNC40NSwzLjI1IEwzLjE1LDQuNTUgQzIuOTUsNC43NSAyLjk1LDUuMTUgMy4xNSw1LjM1IEwxMC44NSwxMy4wNSBMMy4xNSwyMC43NSBDMi45NSwyMC45NSAyLjk1LDIxLjM1IDMuMTUsMjEuNTUgTDQuNDUsMjIuODUgQzQuNjUsMjMuMDUgNS4wNSwyMy4wNSA1LjI1LDIyLjg1IEwxMi45NSwxNS4xNSBMMjAuNjUsMjIuODUgQzIwLjg1LDIzLjA1IDIxLjI1LDIzLjA1IDIxLjQ1LDIyLjg1IEwyMi43NSwyMS41NSBDMjIuOTUsMjEuMzUgMjIuOTUsMjAuOTUgMjIuNzUsMjAuNzUgTDE1LjA1LDEzLjA1IEwyMi43NSw1LjM1IEMyMy4wNSw1LjE1IDIzLjA1LDQuNzUgMjIuNzUsNC40NSBMMjEuNDUsMy4xNSBaIiBpZD0iaWNvbi1jbG9zZS1tZWQtZ3JleSIgc2tldGNoOnR5cGU9Ik1TU2hhcGVHcm91cCI+PC9wYXRoPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==');
}

// SIZE
.icon-cross-sm() {
	width: 16px;
	height: 16px;
}

.icon-cross-med() {
    width:24px;
    height:24px;
}

// CLASSES
.dna-icon-cross-sm-red {
	.dna-icon;
	.icon-cross-sm;
	.icon-cross-red;
}

.dna-icon-cross-med-red {
	.dna-icon;
	.icon-cross-med;
	.icon-cross-red;
}

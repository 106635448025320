@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.button {
  width: 180px;
  margin-left: 24px;
  margin-right: 12px;

  @include large {
    width: 240px;
    margin-left: auto;
    margin-right: 0;
  }
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.image {
  width: 100%;
  background-color: $color-white-smoke;
  order: 1;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

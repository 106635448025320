@import '../../../dna-core/components/global/global';
@import '../../../dna-core/components/variables/components/typography';

//-------------------------------------------------
// HEADINGS
//-------------------------------------------------

// Extend
.dna-heading-base {
    line-height: @line-height;
    font-weight: @normal;
    color: @color-default;
}

.dna-h1 {
    &:extend(.dna-heading-base);
    font-size: 24px;

    @media @mq-l {
        font-size: 36px;
    }
}
.dna-h2 {
    &:extend(.dna-heading-base);
    font-size: 20px;
    line-height: 1.3;

    @media @mq-l {
        font-size: 28px;
        line-height: 1.32142857142857;
    }
}
.dna-h3 {
    &:extend(.dna-heading-base);
    font-size: 19px;

    @media @mq-l {
        font-size: 24px;
    }
}
.dna-h4 {
    &:extend(.dna-heading-base);
    font-size: 17px;

    @media @mq-l {
        font-size: 21px;
    }
}
.dna-h5 {
    &:extend(.dna-heading-base);
    font-size: 16px;

    @media @mq-l {
        font-size: 18px;
    }
}

//-------------------------------------------------
// TEXT
//-------------------------------------------------
// Mixin
.dna-text-base(@color: @color-default; @font-weight: @light;) {
    font-weight: @font-weight;
    color: @color;
}

// DEFAULT SIZE TEXT
p, li,
.dna-text-default {
    .dna-text-base();
    font-size: 14px;
    line-height: 1.42857142857143;

    @media @mq-l {
        font-size: 16px;
        line-height: 1.5625;
    }
}
.dna-text-default-regular {
    &:extend(.dna-text-default);
    font-weight: @normal;
}

.dna-text-default-lite {
    &:extend(.dna-text-default);
    color: @color-lite;
}

// X-LARGE SIZE TEXT
.dna-text-xl {
    .dna-text-base();
    font-size: 16px;
    line-height: 1.3125;

    @media @mq-l {
        font-size: 24px;
        line-height: 1.55555555555556;
    }
}

// LARGE SIZE TEXT
.dna-text-l {
    .dna-text-base();
    font-size: 16px;
    line-height: 1.3125;

    @media @mq-l {
        font-size: 18px;
        line-height: 1.55555555555556;
    }
}

// SMALL SIZE TEXT
.dna-text-s {
    .dna-text-base();
    font-size: 13px;
    line-height: 1.38461538461538;

    @media @mq-l {
        font-size: 14px;
        line-height: 1.35714285714286;
    }
}
.dna-text-s-regular {
    &:extend(.dna-text-s);
    font-weight: @normal;
}
.dna-text-s-lite {
    &:extend(.dna-text-s);
    color: @color-lite;
}

// XSMALL SIZE TEXT
.dna-text-xs-lite {
    font-weight: @light;
    color: @color-liter;
    font-size: 11px;
    line-height: 1.36363636363636;

    @media @mq-l {
        font-size: 12px;
        line-height: 1.33333333333333;
    }
}

//-------------------------------------------------
// BRAND TEXT
//-------------------------------------------------

// Extend
.dna-text-brand-base {
    font-weight: @normal;
    color: @color-brand;
}

.dna-text-brand-xl {
    &:extend(.dna-text-brand-base);
    font-size: 40px;
    line-height: 1.31666666666667;

    @media @mq-l {
        font-size: 60px;
        line-height: 1.31666666666667;
    }
}
.dna-text-brand-l {
    &:extend(.dna-text-brand-base);
    font-size: 28px;
    line-height: 1.32142857142857;

    @media @mq-l {
        font-size: 40px;
        line-height: 1.3;
    }
}
.dna-text-brand-m {
    &:extend(.dna-text-brand-base);
    font-size: 22px;
    line-height: 1.31818181818182;
}
.dna-text-brand-s {
    &:extend(.dna-text-brand-base);
    font-size: 14px;
    line-height: 1.35714285714286;

    @media @mq-l {
        font-size: 16px;
        line-height: 1.375;
    }
}
.dna-text-brand-xs {
    &:extend(.dna-text-brand-base);
    font-size: 13px;
    line-height: 1.38461538461538;

    @media @mq-l {
        font-size: 14px;
        line-height: 1.35714285714286;
    }
}

// BRAND COLOUR DECORATOR
.dna-text-brand {
    color: @color-brand;
}

//-------------------------------------------------
// TEXT-BASED LINKS
//-------------------------------------------------

//Extends
.dna-text-link-base {
    border: 0;
    background: none;
    color: @color-link;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
.dna-text-link {
    &:extend(.dna-text-link-base all);
    font-weight: @normal;
    font-size: 14px;
    line-height: 1.35714285714286;

    @media @mq-l {
        font-size: 16px;
        line-height: 1.375;
    }
}

p a {
    &:extend(.dna-text-link-base all);
}

//-------------------------------------------------
// DECORATORS
//-------------------------------------------------
.dna-text-light {
    font-weight: @light;
}
.dna-text-normal {
    font-weight: @normal;
}

//-------------------------------------------------
// TEXT LISTS
//-------------------------------------------------

.dna-ulist-text {
	list-style-type: disc;
	margin-left: 17px;

	> li {
		margin-bottom: @space-s;
	}
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.share-button {
  font-weight: 300;
  border-style: none;
  text-align: center;
  position: relative;
  background: none;
  color: #1d67be;

  img {
    margin-top: -8px;
    margin-right: 8px;

    @include large {
      position: absolute;
      left: 12px;
      bottom: 12px;
    }
  }

  &:hover {
    border-style: none;
    background: none;
    color: #1d67be;
    text-decoration: underline;
  }
}

@import '../button/button';
@import './mixins';

//++++++++++++++++++++++++++++++++
//+ Skin styles - OOCSS skins
//++++++++++++++++++++++++++++++++
/*csslint duplicate-properties: false */
.dna-button-default {
	&:extend(.dna-button);
	.default-button-skin(@color: @button-color,
		@background-color: @button-background-color,
		@background-color-hover: @button-background-color-hover,
		@background-color-active: @button-background-color-active,
		@background-color-disabled: @button-color-disabled);
}

.dna-button-brand {
	&:extend(.dna-button);
	.default-button-skin(@color: @button-brand-color,
		@background-color: @button-brand-background-color,
		@background-color-hover: @button-brand-background-color-hover,
		@background-color-active: @button-brand-background-color-active,
		@background-color-disabled: @button-brand-background-color-disabled);
}

.dna-button-primary {
	&:extend(.dna-button);
	.default-button-skin(@color: @button-primary-color,
		@background-color: @button-primary-background-color,
		@background-color-hover: @button-primary-background-color-hover,
		@background-color-active: @button-primary-background-color-active,
		@background-color-disabled: @button-primary-background-color-disabled);
}

.dna-button-secondary {
	&:extend(.dna-button);
	.default-button-skin(@color: @button-secondary-color,
		@background-color: @button-secondary-background-color,
		@background-color-hover: @button-secondary-background-color-hover,
		@background-color-active: @button-secondary-background-color-active,
		@background-color-disabled: @button-secondary-background-color-disabled);
}

.dna-button-ghost {
	&:extend(.dna-button);

	.default-button-skin(@color: @button-ghost-color,
		// FALLBACK
		@background-color: @button-ghost-background-color,
		@background-color-hover: @button-ghost-background-color-hover,
		@background-color-active: @button-ghost-background-color-active,
		@background-color-disabled: @button-ghost-background-color-disabled);

	.ghost-button-skin(@button-border-color: @button-ghost-border-color;
		@button-border-color-fallback: @white;
		@button-border-color-disabled: @button-ghost-border-color-disabled;
		@button-border-color-disabled-fallback: @button-ghost-clear-border-color-disabled-fallback;
		@border-radius: @button-ghost-border-radius);
}

.dna-button-ghost-clear {
	&:extend(.dna-button);

	.default-button-skin(@color: @button-ghost-clear-color,
		@background-color: @button-ghost-clear-background-color,
		@background-color-hover: @button-ghost-clear-background-color-hover,
		@background-color-active: @button-ghost-clear-background-color-active,
		@background-color-disabled: @button-ghost-clear-background-color-disabled);

		// Uses same decorator as the ghost button
		.ghost-button-skin(@button-border-color: @button-ghost-clear-border-color;
			@button-border-color-fallback: @white;
			@button-border-color-disabled: @button-ghost-clear-border-color-disabled;
			@button-border-color-disabled-fallback: @button-ghost-clear-border-color-disabled-fallback;
			@border-radius: @button-ghost-clear-border-radius);
}
/*csslint duplicate-properties: true */

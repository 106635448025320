@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.donation {
  display: flex;
  margin-top: 20px;
}

.avatar {
  border: 2px solid $color-white;
  border-radius: 50%;
  flex-shrink: 0;
  height: 50px;
  width: 50px;
  object-position: center;
  object-fit: cover;

  @include xlarge {
    height: 64px;
    width: 64px;
  }
}

.information {
  border-bottom: 1px $color-very-light-grey solid;
  flex-direction: column;
  margin-left: 20px;
  padding-bottom: 10px;
  width: 100%;
}

.date {
  color: $color-nobel;
  float: right;
  font-size: 0.8em;
  margin-bottom: 8px;
}

.supporter {
  display: inline;
  font-size: 1.2em;
  font-weight: 400;
}

.message {
  line-height: 24px;
  font-size: 1em;
  font-weight: 300;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;

  @include large {
    font-size: 1em;
  }
}

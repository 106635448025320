@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.medium {
  width: 100%;
  margin-bottom: 12px;
}

.wrapper {
  position: relative;
  margin: 0 auto;
  text-align: center;
}

img {
  max-height: 500px;
}

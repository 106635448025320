@import '~@justgiving/styles/src/styles.scss';

/** MOVE TO JG TOOLKIT WHERE POSSIBLE */

.jg-tal {
  text-align: left !important;
}

.jg-fwl {
  font-weight: 300;
}

.jg-bdb--very-light-grey {
  border-bottom: 1px solid $color-very-light-grey;
}

.jg-space-phmd\@sm {
  @include small-only {
    padding-left: 18px !important;
    padding-right: 18px !important;
  }
}

.jg-space-mbn\@sm {
  @include small-only {
    margin-bottom: 0 !important;
  }
}

.jg-btn-group--select {
  .jg-btn.is-active {
    color: $color-white;
  }
}

.jg-nowrap {
  white-space: nowrap;
}

.jg-relative {
  position: relative;
}

.jg-display-absolute {
  position: absolute;
}

.jg-block {
  display: block;
}

// DNA overrides the colors of links that are styled like buttons
// todo: remove when DNA is dead ☠️
.jg-btn--paypal,
.jg-btn--brand {
  color: $color-white !important;
}
.jg-btn--ghost {
  color: $color-nero !important;
}

.jg-form-label {
  margin-bottom: $global-spacing-unit-xs;
}

.jg-fwl {
  font-weight: 300;
}

// Toolkit adds an underline to all anchor tags (on hover)
// so we need to disable it for anchor tags that look like buttons
// TODO: should we add this behaviour to the Toolkit?
a.jg-btn,
a.dna-button {
  &:hover {
    text-decoration: none;
  }
}

.jg-font-300 {
  font-weight: 300;
}

.jg-invisible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.jg-list-style--disc {
  list-style-type: disc;
}

.jg-list-position--inside {
  list-style-position: inside;
}

.jg-svg-md {
  width: 150px;
  height: 150px;
  margin: 0 auto 24px auto;
}

.jg-svg-sm {
  width: 80px;
  height: 80px;
  margin: 0 auto 24px auto;
}

.jg-icon--small {
  width: 18px;
  height: 18px;
}

// DNA is setting the padding on input[type="number"] this overrides the toolkit
input[type='number'] {
  padding: $global-spacing-unit-sm;
}

.jg-width--300 {
  max-width: 300px;
}

.jg-bd--radius-3px {
  border-radius: 3px;
}

// fuck chrome!!!!!!!!!!!
#chrome-header .nav-spacer {
  height: auto !important;
}

#chrome-header .global-header {
  position: relative !important;
}

textarea.jg-form-control {
  resize: none;
}

.jg-bdtn {
  border-top: none;
}

#chrome-footer .divide {
  display: none;
}

#chrome-footer {
  border-top: 1px solid $color-very-light-grey;
}

.jg-width-100pc {
  width: 100%;
}

.jg-height-100pc {
  height: 100%;
}

.jg-text-dec--none {
  text-decoration: none;
}

.jg-preserve-whitespace {
  white-space: pre-line;
}

.jg-icon--xxl {
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

@include xsmall {
  .jg-bdl--none\@xs {
    border-left: none;
  }

  .jg-bdr--none\@xs {
    border-right: none;
  }

  .jg-flex-column\@xs {
    flex-direction: column;
  }
}

@include medium {
  .jg-bd\@md {
    border-style: solid;
    border-width: 1px;
  }

  .jg-flex-row\@md {
    flex-direction: row;
  }
}

// The below is because the media query border color isn't being picked up...
.jg-bd--very-light-grey {
  border-color: $color-very-light-grey !important;
}

.jg-flex-basis-50 {
  flex-basis: 50%;
}





@import '../../../../dna-core/components/icons/icon/tick';
@import '../../../../dna-core/components/icons/icon/cross';

//++++++++++++++++++++++++++++++++
//+ DNA credit card input
//++++++++++++++++++++++++++++++++

.dna-input-credit-card {
	padding-right:48px;
}

// Below is created by JS if using vanilla module
// (will need to create manually if using framework - see demo)
.dna-input-credit-card-wrap {
    position:relative;

    &:before{
        content:'';
        position:absolute;
        top:5px;
        right:12px;
        opacity:0;
        transition:opacity 0.7s;
    }

    &.dna-input-credit-card-wrap-valid:before {
        .dna-icon-tick-sm-green;
        opacity:1;
    }

    &.dna-input-credit-card-wrap-valid-pending:before {
        .dna-icon-tick-sm-grey;
        opacity:1;
    }

    &.dna-input-credit-card-wrap-is-error:before {
        .dna-icon-cross-sm-red;
        opacity:1;
        top:9px;

        @media @mq-l {
            top:13px;
        }
    }

    @media @mq-l {
        &:before{
            top:9px;
        }
    }
}

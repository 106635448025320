@import '../../..//dna-core/components/global/global';
@import '../../..//dna-core/components/variables/components/button';
@import '../../..//dna-core/components/icons/icon/facebook';
@import '../../..//dna-core/components/icons/icon/twitter';
@import '../../..//dna-core/components/icons/icon/email';
@import '../../..//dna-core/components/icons/icon/whatsapp';
@import '../button/button';
@import '../button-default/mixins';
@import './mixins';

.dna-button-loading {
	.dna-button-loading(@color: @button-default-loading-color);
}

//++++++++++++++++++++++++++++++++
//+ Social buttons
//++++++++++++++++++++++++++++++++

// Facebook
.dna-button-facebook {
	&:extend(.dna-button);
	.social-button-skin(@background-color: @button-facebook-background-color;
		@color: @button-facebook-color;
		@background-color-hover: @button-facebook-background-color-hover;
		@background-color-active: @button-facebook-background-color-active;
		@background-color-disabled: @button-facebook-background-color-disabled;
		@icon-top: 10px;
		@icon-left: 20px);
	.social-button-skin-large(@icon-top: 13px);
	.social-button-skin-collapse(@icon-left: 17px; @icon-left-bp-l: 20px);
	.social-button-skin-toggle(@icon-left: 14px;
		@icon-top: 8px;
		@background-color: @button-facebook-background-color-disabled;
		@background-color-active: @button-facebook-background-color-active);

	&:before {
		.dna-icon-facebook-sm-white;
		.is-not-authorized& {
			.dna-icon-facebook-sm-blue;
		}
	}

}

.dna-button-container {
	position: relative;
}

.dna-button-counter {
	&:extend(.dna-button);
	.social-button-counter(@color: @button-facebook-color);
	position: absolute;
	line-height: @button-default-height;
	right: 20px;
	top: -3px;
	width: auto;

	@media @mq-l{
		line-height: @button-bp-large-height;
	}
}

// Twitter
.dna-button-twitter {
	&:extend(.dna-button);
	.social-button-skin(@background-color: @button-twitter-background-color;
		@color: @button-twitter-color;
		@background-color-hover: @button-twitter-background-color-hover;
		@background-color-active: @button-twitter-background-color-active;
		@background-color-disabled: @button-twitter-background-color-disabled;
		@icon-top: 13px;
		@icon-left: 15px);
	.social-button-skin-large(@icon-top: 15px);
	.social-button-skin-collapse(@icon-left: 12px; @icon-left-bp-l: 15px);
	.social-button-skin-toggle(@icon-left: 9px;
		@icon-top: 10px;
		@background-color: @button-twitter-background-color-disabled;
		@background-color-active: @button-twitter-background-color-active;);
	&:before {
		.dna-icon-twitter-sm-white;
	}
}

// Email (defaults to ghost)
.dna-button-ghost-email {
	&:extend(.dna-button);
	.social-button-skin(@padding-left: 49px;
		@background-color: @button-ghost-background-color;
		@color: @button-ghost-color;
		@background-color-hover: @button-ghost-background-color-hover;
		@background-color-active: @button-ghost-background-color-active;
		@background-color-disabled: @button-ghost-background-color-disabled;
		@icon-top: 8px;
		@icon-left: 14px);
	.ghost-button-skin(@border-radius: @button-ghost-border-radius;
		@button-border-color: @button-ghost-border-color;
		@button-border-color-fallback: @button-ghost-border-color;
		@button-border-color-disabled: @button-ghost-border-color-disabled;
		@button-border-color-disabled-fallback: @button-ghost-border-color-disabled);
	.social-button-skin-large(@icon-top: 10px);
	.social-button-skin-collapse(@icon-left: 9px; @icon-left-bp-l: 14px);
	&:before {
		.dna-icon-email-sm-grey;
	}
}

// Twitter ghost
.dna-button-ghost-twitter {
	&:extend(.dna-button);
	.social-button-skin(@padding-left: 49px;
		@background-color: @button-ghost-background-color;
		@color: @button-ghost-color;
		@background-color-hover: @button-ghost-background-color-hover;
		@background-color-active: @button-ghost-background-color-active;
		@background-color-disabled: @button-ghost-background-color-disabled;
		@icon-top: 12px;
		@icon-left: 14px);
	.ghost-button-skin(@border-radius: @button-ghost-border-radius;
		@button-border-color: @button-ghost-border-color;
		@button-border-color-fallback: @button-ghost-border-color;
		@button-border-color-disabled: @button-ghost-border-color-disabled;
		@button-border-color-disabled-fallback: @button-ghost-border-color-disabled);
	.social-button-skin-large(@icon-top: 14px);
	.social-button-skin-collapse(@icon-left: 12px; @icon-left-bp-l: 14px);
	&:before {
		.dna-icon-twitter-sm-blue;
	}
	&.is-disabled:before {
		.dna-icon-twitter-sm-grey;
	}
}

// What'sApp
.dna-button-whatsapp {
	&:extend(.dna-button);
	.social-button-skin(@background-color: @button-whatsapp-background-color;
		@color: @button-whatsapp-color;
		@background-color-hover: @button-whatsapp-background-color-hover;
		@background-color-active: @button-whatsapp-background-color-active;
		@background-color-disabled: @button-whatsapp-background-color-disabled;
		@icon-top: 6px;
		@icon-left: 10px);
	.social-button-skin-large(@icon-top: 8px);
	&:before {
		.dna-icon-whatsapp-sm-white;
	}
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.share-button-container {
  text-align: center;
  display: inline-block;
  border-radius: 4px;
  font-size: 10px;
  width: 100%;

  @include large {
    padding: 15px;
    width: 95px;
    height: 120px;
  }

  &:hover {
    background-color: #f5f5f5;
  }
}

.share-button {
  cursor: pointer;
  width: 275px;
  background-color: transparent;
  background-position: left top;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  height: 50px;
  width: 100%;
  padding-top: 5px;
  text-indent: 50px;
  border-bottom: 1px solid #ccc;

  &:focus,
  &:active,
  &:visited,
  &:focus-within,
  &:focus-visible {
    outline: none;
  }

  @include large {
    background-position: center top;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    width: 65px;
    height: auto;
    text-indent: 0;
    border: none;
    padding: 75px 0 0 0;
  }
}

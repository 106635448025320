@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

#thank-you-for-helping-modal {
  margin-top: 30%;

  @media screen and (min-width: px2em(breakpoint(small))) {
    margin-top: 0;
  }
}

.high-five {
  @media screen and (min-width: px2em(breakpoint(small))) {
    width: 180px;
    height: auto;
  }
}

.title {
  font-size: 18px;

  @media screen and (min-width: px2em(breakpoint(small))) {
    font-size: 32px;
  }
}

.subtitle {
  margin-bottom: 0;
  line-height: 26px;

  @media screen and (min-width: px2em(breakpoint(small))) {
    font-size: 18px;
    width: 70%;
    margin: auto;
  }
}

.button-container {
  @media screen and (min-width: px2em(breakpoint(small))) {
    width: 60%;
  }
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.icon-positioning {
  bottom: -2px;
}

.tooltip-click-wrapper {
  .tooltip {
    opacity: 0;
    display: none;

    &.tooltip--open {
      opacity: 1 !important;
      display: block;
      z-index: 1;
    }
  }
}

.tooltip-hover-wrapper {
  .tooltip {
    display: none;
    opacity: 0;
  }

  &:hover {
    .tooltip {
      opacity: 1 !important;
      display: block;
    }
  }
}

.tooltip-right {
  background-color: $color-white;
  border: 1px solid $color-very-light-grey;
  border-radius: 3px;
  left: 100%;
  margin-left: 20px;
  max-height: auto;
  max-width: 400px;
  min-width: 200px;
  position: absolute;
  transition: opacity 0.5s;
  top: 50%;
  transform: translateY(-50%);

  @include large {
    min-width: 400px;
  }
}

.tooltip-left {
  background-color: $color-white;
  border: 1px solid $color-very-light-grey;
  border-radius: 3px;
  right: 100%;
  margin-right: 20px;
  max-height: auto;
  max-width: 400px;
  min-width: 200px;
  position: absolute;
  transition: opacity 0.5s;
  top: 50%;
  transform: translateY(-50%);

  @include large {
    min-width: 400px;
  }
}

.tooltip-bottom {
  background-color: $color-white;
  border: 1px solid $color-very-light-grey;
  border-radius: 3px;
  top: 100%;
  bottom: auto;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
  max-height: auto;
  max-width: 400px;
  min-width: 200px;
  position: absolute;
  transition: opacity 0.5s;
  transform: translateX(-50%);
  z-index: 100;

  @include large {
    min-width: 400px;
  }
}

.tooltip-top {
  background-color: $color-white;
  border: 1px solid $color-very-light-grey;
  border-radius: 3px;
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
  max-height: auto;
  // max-width: 400px;
  // min-width: 300px;
  position: absolute;
  transition: opacity 0.5s;
  max-width: 100vh;
  min-width: 300px;

  @include large {
    min-width: 400px;
  }
}

.arrow-bottom {
  &:after,
  &:before {
    border: solid transparent;
    content: ' ';
    height: 0;
    left: 50%;
    position: absolute;
    pointer-events: none;
    top: 100%;
    width: 0;
  }

  &:after {
    border-top-color: $color-white;
    border-width: 12px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: $color-very-light-grey;
    border-width: 13px;
    margin-left: -11px;
  }
}

.arrow-top {
  &:after,
  &:before {
    border: solid transparent;
    content: ' ';
    height: 0;
    left: 50%;
    position: absolute;
    pointer-events: none;
    top: -26px;
    width: 0;
  }

  &:after {
    border-bottom-color: $color-white;
    border-width: 14px;
    margin-left: -12px;
  }

  &:before {
    border-bottom-color: $color-very-light-grey;
    border-width: 13px;
    margin-left: -11px;
  }
}

.arrow-right {
  &:after,
  &:before {
    border: solid transparent;
    content: ' ';
    height: 0;
    left: 100%;
    position: absolute;
    pointer-events: none;
    top: 50%;
    width: 0;
  }

  &:after {
    border-left-color: $color-white;
    border-width: 10px;
    margin-top: -10px;
  }

  &:before {
    border-left-color: $color-very-light-grey;
    border-width: 12px;
    margin-top: -12px;
  }
}

.arrow-left {
  &:after,
  &:before {
    border: solid transparent;
    content: ' ';
    height: 0;
    position: absolute;
    pointer-events: none;
    right: 100%;
    top: 50%;
    width: 0;
  }

  &:after {
    border-right-color: $color-white;
    border-width: 10px;
    margin-top: -10px;
  }

  &:before {
    border-right-color: $color-very-light-grey;
    border-width: 12px;
    margin-top: -12px;
  }
}

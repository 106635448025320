@import '../../../dna-core/components/global/global';
@import '../../../dna-core/components/variables/components/form';
@import '../../../dna-core/components/icons/icon/chevron';
@import '../input/input';

//++++++++++++++++++++++++++++++++
//+ DNA select
//++++++++++++++++++++++++++++++++

.dna-select {
	&:extend(.dna-input all);
	display:inline-block;
	width:auto;
	max-width:100%;
	min-width:100px;
	padding-right:52px;
	position:relative;
	cursor:pointer;
	padding-top:11px;
	padding-bottom:13px;

	@media @mq-l {
		padding: 12px;
		padding-right:52px;
	}

	&.dna-select-open{
		border-color:@form-border-color-focused;

		&:before {
			content:'';
			width:100%;
			height:1px;
			background:@form-component-background-color-default;
			position:absolute;
			bottom:0;
			left:0;
			z-index:2;
		}
	}
}

.dna-select-arrow{
	display:block;
	padding:0 12px;
	height:34px;
	line-height:34px;
	position:absolute;
	top:0;
	right:0;
	z-index:3;
	background: @form-component-background-color-default;

	&:after{
		content:'';
		.dna-icon-chevron-down-sm-grey;

		.no-touchevents .dna-select-open & {
			.dna-icon-chevron-up-sm-grey;
		}
	}

	@media @mq-l{
		height:42px;
		line-height:42px;
	}
}

.dna-select-value {
	display:inline-block;
	width:100%;
	color:@form-font-color;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
}

.dna-select-options {
	display:none;

	.no-touchevents & {
		&:extend(.dna-input all);
		visibility:hidden;
		padding:0;
		position:absolute;
		top:~"calc(100% - 1px)";
		width:~"calc(100% + 2px)";
		height:auto;
		z-index:1;
		color:inherit;
		border-color:@form-border-color-focused;
	}

	.no-touchevents .dna-select-open & {
		visibility:visible;
	}

	&.dna-select-options-left {
		left:-1px;
	}

	&.dna-select-options-right {
		right:-1px;
	}
}

.dna-select-option {
	padding:10px 12px;
	font-size:16px;
	line-height:1.25;
	cursor:pointer;

	&:hover,
	&:focus,
	&.dna-select-option-selected{
		background-color:@form-select-dropdown-hover;
		outline:none;
	}
}

.js-dna-select {
	position:absolute;
	top:0;
	left:0;
	z-index:4;
	width:100%;
	height:100%;
	appearance:none;
	opacity:0;

	.no-touchevents & {
		left:-999em;
	}
}

//++++++++++++++++++++++++++++++++
//+ DNA select size variants
//++++++++++++++++++++++++++++++++
// Medium
.dna-select-medium {
	height: @input-height-m;
	font-size: @form-font-size-m;
	padding-top: 13px;
}
.dna-select-options-medium {
	font-size: @form-font-size-m;
}

// Large
.dna-select-large {
	height: @input-height-l;
	font-size: @form-font-size-m;
	padding-top: 20px;
}
.dna-select-arrow-large {
	top: 7px;
}
.dna-select-options-large {
	font-size: @form-font-size-m;
}

//++++++++++++++++++++++++++++++++
//+ DNA select width variants
//++++++++++++++++++++++++++++++++

.dna-select-full-width {
	width: 100%!important;
}

//++++++++++++++++++++++++++++++++
//+ IE8 styles
//++++++++++++++++++++++++++++++++
//
.ie8 {
	.js-dna-select {
		position:static;
		width:auto;
		height:auto;
	}
}

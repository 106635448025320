//++++++++++++++++++++++++++++++++
//+ Base styles - OOCSS structure
//++++++++++++++++++++++++++++++++

// Base extends
.dna-button {
	font-family: @font-base;
	font-weight: @button-font-weight;
	display: inline-block;
	width:100%;
	min-height: @button-default-height;
	padding:@space-xs @space-s;
	border:0;
	border-radius: @button-border-radius;
	font-size:@button-font-size-s;
	line-height: @button-line-height-s;
	text-align:center;
	transition:all @button-transition-duration;
	vertical-align: middle;
	overflow: hidden;
	outline: 0;

	@media @mq-l{
		min-height: @button-bp-large-height;
		font-size:@button-font-size-m;
		line-height: @button-line-height-m;
	}
}
@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.tooltip {
  .tooltip-container {
    visibility: hidden;
    opacity: 0;
  }
}

.tooltip-open {
  .tooltip-container {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
}

.tooltip-trigger {
  cursor: pointer;
  visibility: visibility;
  display: flex;
  justify-content: flex-end;
}

.tooltip-container {
  background-color: $color-white;
  transition: visibility 0.2s, opacity 0.2s linear;
  top: 40px;
  position: absolute;
  right: 11px;
  width: 150px;
  padding: 8px;
  border: 1px solid $color-very-light-grey;

  @include medium {
    top: 49px;
    right: 13px;
  }

  @include large {
    top: 49px;
    right: 13px;
  }
}

.tooltip-delete {
  background: none;
  border: none;
  font-weight: 300;
  color: $color-nero;
  font-size: 13px;
  line-height: 1.38461538461538;
  width: 100%;
  text-align: left;

  @include large {
    font-size: 14px;
    line-height: 1.35714285714286;
  }
}

.tooltip-container:after,
.tooltip-container:before {
  bottom: 100%;
  left: 90%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;

  @include medium {
    left: 86%;
  }
}

.tooltip-container:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: $color-white;
  border-width: 10px;
  margin-left: -10px;
}
.tooltip-container:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: $color-very-light-grey;
  border-width: 12px;
  margin-left: -12px;
}

@import '../../../dna-core/components/global/global';
@import '../../../dna-core/components/variables/components/form';

//++++++++++++++++++++++++++++++++
//+ DNA radio
//++++++++++++++++++++++++++++++++

.dna-radio {
	.dna-invisible;
}

// Extend: Outer border
.dna-radio-border {
	content:'';
	position:absolute;
	top:1px;
	left:0;
	width:18px;
	height:18px;
	border: solid @form-border-color 1px;
	border-radius: 50%;
	background-color: @form-component-background-color-default;
}

// Extend: Inner check mark
.dna-radio-check-mark {
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	background-color: @form-check-color;
	border-radius: 50%;
	border: 0;
	position: absolute;
	top: 5px;
	left: 4px;
}

.dna-radio + .dna-label {
	display:inline-block;
	position:relative;
	font-size:@form-font-size-m;
	padding:0 0 0 26px;
	line-height:1.2;
	cursor:pointer;
}

.dna-radio:disabled + .dna-label {
	color: @form-font-color-disabled;
	cursor:default;
}

.dna-radio + .dna-label:before {
	&:extend(.dna-radio-border);
}

.dna-radio:checked + .dna-label:after {
	&:extend(.dna-radio-check-mark);
}

.dna-radio:disabled + .dna-label:before {
	&:extend(.dna-radio-border);
	border: solid @form-border-color-disabled 1px;
}

.dna-radio:checked:disabled + .dna-label:after {
	&:extend(.dna-radio-check-mark);
	background-color: tint(@form-check-color, 70%);
}

// IE8 styles
.ie8 {

	.dna-radio {
		border:inherit;
		clip:inherit;
		height:inherit;
		margin:inherit;
		overflow:inherit;
		padding:inherit;
		position:static;
		width:inherit;
	}

	 .dna-radio + .dna-label {
		display:inline-block;
		position:static;
		font-size:@form-font-size-m;
		padding:0;
		line-height:1.2;
		cursor:pointer;
	}
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  text-align: center;
}

.image {
  background-image: url('./placeholder.svg');
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100%;
  display: block;
  height: 155px;
  margin: 0 auto 12px;
  width: 172px;
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  background-color: $color-white;
  border: 1px solid $color-very-light-grey;
  margin-bottom: 28px;

  @include medium {
    border: 0;
  }
}

.avatar-wrapper {
  background-color: $color-white-smoke;
  border: 0;
  display: none;
  float: left;
  padding-top: 10px;
  width: 112px;

  @include medium {
    display: block;
  }
}

.avatar {
  border-radius: 50%;
  border: 2px solid $color-white;
  float: left;
  height: 35px;
  margin-right: 10px;
  object-position: center;
  object-fit: cover;
  width: 35px;

  @include medium {
    float: right;
    height: 64px;
    margin-right: 0;
    width: 64px;
  }
}

.date {
  clear: both;
  padding: 4px 0;
  width: 65px;

  @include medium {
    float: right;
    text-align: center;
  }
}

.content-wrapper {
  background-color: $color-white;
  border-radius: 2px;
  position: relative;
  width: 100%;

  @include medium {
    border: 1px solid $color-very-light-grey;
    float: left;
    margin-bottom: 28px;
    margin-left: 20px;
    width: calc(100% - 134px);

    &:after,
    &:before {
      border: solid transparent;
      content: ' ';
      height: 0;
      pointer-events: none;
      position: absolute;
      right: 100%;
      top: 40px;
      width: 0;
    }

    &:after {
      border-right-color: $color-white;
      border-width: 10px;
      margin-top: -10px;
    }

    &:before {
      border-right-color: $color-very-light-grey;
      border-width: 12px;
      margin-top: -12px;
    }
  }
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.menu {
  flex: 1 auto;
}

.item {
  display: inline-block;
  border-bottom: 4px solid transparent;
  margin: 0 8px;
  position: relative;
  text-align: center;

  a,
  button {
    display: block;
    font-weight: 400;
    padding: 29px 16px 18px 16px;
    cursor: pointer;
    color: $color-empress;
    text-decoration: none;

    @include medium {
      padding: 29px 16px 18px 16px;
    }
  }
}

.active {
  @extend .item;
  border-bottom: 6px solid $color-brand-primary;

  a,
  button {
    color: $color-brand-primary;
  }
}

.panel {
  margin-top: 52px;

  @include large {
    margin-top: 56px;
  }
}

.badge {
  background: #e63743;
  color: $color-white-smoke;
  height: 16px;
  width: 16px;
  line-height: 16px;
  padding: 0 4px;
  border-radius: 10px;
  position: absolute;
  top: 15px;
  right: -8px;

  @include medium {
    height: 18px;
    width: 18px;
    line-height: 18px;
    right: -3px;
  }
}

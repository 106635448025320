//++++++++++++++++++++++++++++++++
//+ DNA input type number
//++++++++++++++++++++++++++++++++

input[type="number"]{
	-moz-appearance:textfield;
	padding: 0 @input-padding;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button{
	-webkit-appearance:none;
	margin:0;
}
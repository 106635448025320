// Note: @size must be an even number otherwise the animation will 'wobble' in some browsers
.dna-loading(@color:@color; @size:24px; @margin:2px; @vapourTrailWidth: 2px;) {
	width: @size + @margin * 2;
	height: @size + @margin * 2;

	.cssanimations & {
		@keyframes rotate {
			0% {
				transform: rotate(0deg);
			}
			50% {
				transform: rotate(180deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}

		&:after {
			background-color: @color;
			width: @size;
			height: @size;
			border-radius: 100%;
			margin: @margin;
			content: '';
			animation-fill-mode: both;
			border: @vapourTrailWidth solid @color;
			border-bottom-color: transparent;
			background: transparent !important;
			display: inline-block;
			animation: rotate 1s 0s linear infinite;

		}
	}

	.no-cssanimations & {
		&:after {
			color: @color;
			font-size: @size;
			content: '...';
			display: inline-block;
			width: @size;
			height: 3px;
			line-height: 3px;
		}		
	}
};


@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.launch-your-page-container {
  display: none;
  margin-top: 40px;
  text-align: center;

  @include xlarge {
    display: flex;
  }
}

.launch-your-page-header {
  font-size: 1.2em;
  font-weight: 400;
}

.launch-your-page-paragraph {
  font-size: 15px;
  margin-top: 6px;
}

.launch-your-page-text {
  height: 64px;
  line-height: 32px;
  margin-left: 20px;
  width: 100%;
}

//++++++++++++++++++++++++++++++++
//+ DNA form-field
//++++++++++++++++++++++++++++++++

// LAYOUT - DEFAULT (STACKED)
.dna-form-field{
	margin:0 0 @space-m;
	
	@media @mq-l{
		margin:0 0 @space-l;
		font-size:@form-font-size-m;
	}
}

// LAYOUT - DISTRIBUTE
.dna-form-field-distribute {
	display: table;
    table-layout: fixed;
    width: 100%;
    margin:0 0 @space-m;

	.dna-form-field {
		display: table-cell;
		padding-right: @space-xs;
		padding-left: @space-xs;
		vertical-align: bottom; 

		@media @mq-m {
			padding-right: @space-s;
			padding-left: @space-s;
			margin:0 0 @space-l;
		}

		&:first-of-type {
			padding-left: 0;
		}
		&:last-of-type {
			padding-right: 0;
		}
		
		.dna-select {
			width: 100%!important;
		}	
	}

	.dna-label {
		padding-left: 2px;
	}
}

// LAYOUT - HALFLING
.dna-form-field-halfling {
	width: 50%;
	padding-right: @space-xs;

	.dna-select {
		width: 100%!important;
	}

	@media @mq-m {
		padding-right: @space-s;
	}
	&.dna-form-field-pull {
		padding-right: @space-xs;
		@media @mq-m {
			padding-right: @space-s;
		}
	}
	&.dna-form-field-push {
		padding-left: @space-xs;
		padding-right: 0;

		@media @mq-m {
			padding-left: @space-s;
		}
	}

}

// LAYOUT - PUSH / PULL
.dna-form-field-pull{
	float:left!important;
}

.dna-form-field-push{
	float:right!important;
}

// LAYOUT - INLINE
.dna-form-field-inline {
	display: table;

	.dna-form-field {
		padding-left: @space-s;
		padding-right: @space-s;
		display: table-cell;
		vertical-align: bottom;

		&:first-of-type {
			padding-left: 0;
		}

		&:last-of-type {
			padding-right: 0;
		}
	}
}
.dna-form-field-inline-relative {
	&:extend(.dna-form-field-inline all);
	width: 100%;
}
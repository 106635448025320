@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.stream {
  position: absolute;
  top: 0;
  height: 100%;
  &--fixed {
    position: absolute;
    top: 0;
    height: 100%;
    @include large {
      z-index: 100;
      position: fixed;
      width: 370px;
      height: 212px;
      right: 18px;
      top: 150px;

      box-shadow: 0px 2px 4px #ae16b7;
    }
  }
}

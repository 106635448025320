// Twitter icons
.icon-twitter-white() {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAyMCAxNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+dHdpdHRlcjE1IGNvcHk8L3RpdGxlPjxwYXRoIGQ9Ik0xNy45NjggNS4wMjRjMCAxLjEwMy0uMTYgMi4yMS0uNDc4IDMuMzItLjMyIDEuMTEtLjgwOCAyLjE3NS0xLjQ2NSAzLjE5NC0uNjU3IDEuMDE4LTEuNDQyIDEuOTItMi4zNTMgMi43LS45MTIuNzgtMi4wMDUgMS40MDYtMy4yOCAxLjg3LTEuMjc3LjQ3LTIuNjQ1LjcwMi00LjEwMy43MDItMi4yNjcgMC00LjM2My0uNjE4LTYuMjktMS44NTIuMzEyLjA0LjYzOC4wNi45NzYuMDYgMS44ODggMCAzLjU4OC0uNTkzIDUuMDk4LTEuNzc1LS44ODUtLjAxMy0xLjY3Ni0uMjktMi4zNzMtLjgyOC0uNjk1LS41NC0xLjE4LTEuMjIzLTEuNDU0LTIuMDUuMzM4LjA1Mi41OTIuMDc4Ljc2Mi4wNzguMjg2IDAgLjYxOC0uMDUyLjk5NS0uMTU3LS45NS0uMTg0LTEuNzUtLjY2LTIuNDAyLTEuNDNDLjk1IDguMDkuNjI2IDcuMjEzLjYyNiA2LjIyN2MuNjkuMjkgMS4zNC40NiAxLjk1Mi40NzNDMS4zOTMgNS44OTYuOCA0Ljc0Ny44IDMuMjVjMC0uNzM2LjE5LTEuNDMyLjU2Ny0yLjA5IDEuMDI4IDEuMjc2IDIuMjggMi4yOTQgMy43NSAzLjA1NiAxLjQ3Ljc2MiAzLjA0NiAxLjE5IDQuNzI2IDEuMjgtLjA2NC0uMjUtLjA5Ny0uNTY0LS4wOTctLjk0NiAwLTEuMTQzLjQtMi4xMTcgMS4yLTIuOTI2LjgwMi0uODA4IDEuNzY4LTEuMjEyIDIuOS0xLjIxMiAxLjIgMCAyLjE5NS40MzMgMi45OSAxLjMuODk4LS4xNyAxLjc3LS41MDUgMi42MTctMS4wMDQtLjMyNSAxLjAxLS45MzIgMS43OC0xLjgxNiAyLjMwNi44NDYtLjEyIDEuNjM0LS4zNDMgMi4zNjMtLjY3LS41NDcuODQtMS4yMzIgMS41NTctMi4wNSAyLjE0Ny4wMTIuMTIuMDE4LjI5Ny4wMTguNTM0eiIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
}

.icon-twitter-blue() {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+dHdpdHRlcjE1IGNvcHkgMjwvdGl0bGU+PHBhdGggZD0iTTE3Ljk2OCA1LjQxYzAgMS4xMDItLjE2IDIuMjEtLjQ3OCAzLjMyLS4zMiAxLjExLS44MDggMi4xNzQtMS40NjUgMy4xOTQtLjY1NyAxLjAxOC0xLjQ0MiAxLjkxOC0yLjM1MyAyLjctLjkxMi43OC0yLjAwNSAxLjQwNS0zLjI4IDEuODctMS4yNzcuNDY4LTIuNjQ1LjctNC4xMDMuNy0yLjI2NyAwLTQuMzYzLS42MTctNi4yOS0xLjg1LjMxMi4wMzguNjM4LjA1OC45NzYuMDU4IDEuODg4IDAgMy41ODgtLjU5MiA1LjA5OC0xLjc3NC0uODg1LS4wMTMtMS42NzYtLjI5LTIuMzczLS44MjgtLjY5NS0uNTQtMS4xOC0xLjIyMy0xLjQ1NC0yLjA1LjMzOC4wNTMuNTkyLjA4Ljc2Mi4wOC4yODYgMCAuNjE4LS4wNTMuOTk1LS4xNi0uOTUtLjE4My0xLjc1LS42Ni0yLjQwMi0xLjQyOC0uNjUtLjc3LS45NzUtMS42NDUtLjk3NS0yLjYzLjY5LjI4OCAxLjM0LjQ2IDEuOTUyLjQ3MkMxLjM5MyA2LjI4Mi44IDUuMTM0LjggMy42MzRjMC0uNzM1LjE5LTEuNDMuNTY3LTIuMDg4QzIuMzk1IDIuODIgMy42NDcgMy44NCA1LjExNyA0LjZjMS40Ny43NjMgMy4wNDYgMS4xOSA0LjcyNiAxLjI4Mi0uMDY0LS4yNS0uMDk3LS41NjUtLjA5Ny0uOTQ2IDAtMS4xNDQuNC0yLjExOCAxLjItMi45MjcuODAyLS44MSAxLjc2OC0xLjIxMyAyLjktMS4yMTMgMS4yIDAgMi4xOTUuNDM0IDIuOTkgMS4zMDIuODk4LS4xNzMgMS43Ny0uNTA4IDIuNjE3LTEuMDA3LS4zMjUgMS4wMS0uOTMyIDEuNzgtMS44MTYgMi4zMDYuODQ2LS4xMiAxLjYzNC0uMzQzIDIuMzYzLS42NzItLjU0Ny44NC0xLjIzMiAxLjU1OC0yLjA1IDIuMTUuMDEyLjExNy4wMTguMjk0LjAxOC41M3oiIGZpbGw9IiM0MDk4RkMiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==');
}

.icon-twitter-grey() {
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAyMCAxNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48dGl0bGU+dHdpdHRlcjE1IGNvcHk8L3RpdGxlPjxwYXRoIGQ9Ik0xNy45NjggNS4wMjRjMCAxLjEwMy0uMTYgMi4yMS0uNDc4IDMuMzItLjMyIDEuMTEtLjgwOCAyLjE3NS0xLjQ2NSAzLjE5NC0uNjU3IDEuMDE4LTEuNDQyIDEuOTItMi4zNTMgMi43LS45MTIuNzgtMi4wMDUgMS40MDYtMy4yOCAxLjg3LTEuMjc3LjQ3LTIuNjQ1LjcwMi00LjEwMy43MDItMi4yNjcgMC00LjM2My0uNjE4LTYuMjktMS44NTIuMzEyLjA0LjYzOC4wNi45NzYuMDYgMS44ODggMCAzLjU4OC0uNTkzIDUuMDk4LTEuNzc1LS44ODUtLjAxMy0xLjY3Ni0uMjktMi4zNzMtLjgyOC0uNjk1LS41NC0xLjE4LTEuMjIzLTEuNDU0LTIuMDUuMzM4LjA1Mi41OTIuMDc4Ljc2Mi4wNzguMjg2IDAgLjYxOC0uMDUyLjk5NS0uMTU3LS45NS0uMTg0LTEuNzUtLjY2LTIuNDAyLTEuNDNDLjk1IDguMDkuNjI2IDcuMjEzLjYyNiA2LjIyN2MuNjkuMjkgMS4zNC40NiAxLjk1Mi40NzNDMS4zOTMgNS44OTYuOCA0Ljc0Ny44IDMuMjVjMC0uNzM2LjE5LTEuNDMyLjU2Ny0yLjA5IDEuMDI4IDEuMjc2IDIuMjggMi4yOTQgMy43NSAzLjA1NiAxLjQ3Ljc2MiAzLjA0NiAxLjE5IDQuNzI2IDEuMjgtLjA2NC0uMjUtLjA5Ny0uNTY0LS4wOTctLjk0NiAwLTEuMTQzLjQtMi4xMTcgMS4yLTIuOTI2LjgwMi0uODA4IDEuNzY4LTEuMjEyIDIuOS0xLjIxMiAxLjIgMCAyLjE5NS40MzMgMi45OSAxLjMuODk4LS4xNyAxLjc3LS41MDUgMi42MTctMS4wMDQtLjMyNSAxLjAxLS45MzIgMS43OC0xLjgxNiAyLjMwNi44NDYtLjEyIDEuNjM0LS4zNDMgMi4zNjMtLjY3LS41NDcuODQtMS4yMzIgMS41NTctMi4wNSAyLjE0Ny4wMTIuMTIuMDE4LjI5Ny4wMTguNTM0eiIgZmlsbD0iI0NDQyIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
}

.icon-twitter-sm() {
	width:20px;
	height:17px;
}

.dna-icon-twitter-sm-white {
	.dna-icon;
	.icon-twitter-sm;
	.icon-twitter-white;
}

.dna-icon-twitter-sm-blue {
	.dna-icon;
	.icon-twitter-sm;
	.icon-twitter-blue;
}

.dna-icon-twitter-sm-grey {
	.dna-icon;
	.icon-twitter-sm;
	.icon-twitter-grey;
}

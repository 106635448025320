@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.content {
  margin-right: 0;

  @include xlarge {
    flex: 2 1 65%;
    margin-right: 35px;
  }
}

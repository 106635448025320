@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.modal {
  position: absolute;
  top: 0px;
  right: 40px;
  outline: none;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  min-height: 100%;

  @include large {
    top: 60px;
    width: 640px;
    min-height: 0;
    margin-bottom: 50px;
    border-radius: 4px;
  }
}

.modal--large {
  width: 100%;

  @include large {
    width: 780px;
  }
}

.modal-background {
  composes: modal;
  background-color: $color-white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(black, 0.4);
  z-index: 10000;
  overflow: auto;
}

$btn-size-mobile: 14px;
$btn-size-desktop: 20px;

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  color: $color-empress;
  background-repeat: no-repeat;
  width: $btn-size-mobile;
  height: $btn-size-mobile;
  background-size: $btn-size-mobile;
  overflow: hidden;
  text-indent: -9999px;
  background-color: transparent;
  border: none;
  background-position: center;
  z-index: 999;
  margin: 8px 0;
  padding: 16px;

  @include medium {
    margin: 16px;
    padding: 8px;
    width: $btn-size-desktop;
    height: $btn-size-desktop;
    background-size: $btn-size-desktop;
  }

  &:hover {
    text-decoration: none !important;
  }
}

.light-close-button {
  @extend .close-button;
  background-image: url('./light-close.svg');
}

.dark-close-button {
  @extend .close-button;
  background-image: url('./dark-close.svg');
}

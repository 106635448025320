@import "~../../../dna-progress/components/totaliser/totaliser.less";
@import "~../../../dna-progress/components/totaliser/totaliser-base.less";
@import "~../../../dna-progress/components/totaliser/totaliser-mixins.less";
@import "~../../../dna-progress/components/totaliser/totaliser-variables.less";
@import "~../../../dna-typography/components/typography/typography.less";

.dna-card-container {
	font-family: @font-base;
	width: 100%;
	border: 1px solid @very-light-grey;
	font-size: 1rem;
	background-color: @white;
}

.dna-card-page-image-container {
	width: 100%;
	height: 180px;
	overflow: hidden;

	@media @mq-l {
		height: 200px;
	}
}

.dna-card-page-image {
	width: 100%;
	min-height: 200px;
}

.dna-card-information-container {
	padding: 16px;
}

@font-size-small: 17px;
@font-size-large: 21px;
@line-height: 1.4;
@lines-to-show: 2;

.dna-card-title {
	color: @nero;
	display: block;
	display: -webkit-box;
	max-width: 100%;
	height: @font-size-small * @line-height * @lines-to-show;
	margin: 0 auto 20px;
	font-size: @font-size-small;
	line-height: @line-height;
	-webkit-line-clamp: @lines-to-show;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	text-transform: capitalize;

	@media @mq-l {
		height: @font-size-large * @line-height * @lines-to-show;
		font-size: @font-size-large;
	}
}

.dna-card-fundraiser-container {
	display: flex;
	align-items: center;
	margin-bottom: 22px;
}

.dna-card-avatar-container {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	overflow: hidden;
	margin-right: 12px;
}

.dna-card-fundraiser-avatar {
	min-width: 100%;
	max-height: 32px;
}

.dna-card-progress-container {
	display: flex;
}

.dna-card-amount {
	font-weight: @font-weight-light;
	font-size: 1.12em;
	line-height: 1.2;
}

.dna-card-number-of-supporters {
	font-size: 1.1em;
	line-height: 1.2;
}

.dna-card-progress-container {
	margin-right: 12px;
}

.dna-card-progress-information-container {
	flex: 1 auto;
	margin-left: 12px;
}

.dna-card-anchor {
	cursor: pointer;
	text-decoration: none;
}

@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  background-image: url('../images/MOBconfetti.svg');
  background-size: 98%;
  background-repeat: no-repeat;
  background-position: top;

  @include large {
    background-image: url('../images/DTconfetti.svg');
    background-position: center;
  }
}

.green-circle {
  border-radius: 100%;
  width: 64px;
  height: 64px;
  background-color: $color-mountain-meadow;
  margin: 0 auto 13px;

  svg {
    padding-top: 10px;
  }
}

.tick-complete {
  stroke: $color-white;
}

.tick-complete-animated {
  stroke: $color-white;
  stroke-dashoffset: -55;
  stroke-dasharray: 55;
  animation: tick 600ms cubic-bezier(0.45, 0.09, 0.98, 0.76) forwards;
  animation-delay: 500ms;
}

@keyframes tick {
  0% {
    stroke-dashoffset: -55;
  }
  99% {
    stroke-dashoffset: -110;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.grey-cross {
  background-image: url('../images/MOB-close-icon-grey-active.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 13px;
  height: 13px;
  right: 16px;
  top: 16px;
  position: absolute;
  cursor: pointer;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background-image: url('../images/MOB-close-icon-grey-hover.svg');
  }

  @include large {
    background-image: url('../images/DT-close-icon-grey-active.svg');
    width: 20px;
    height: 20px;
    right: 20px;
    top: 20px;

    &:hover {
      background-image: url('../images/DT-close-icon-grey-hover.svg');
    }
  }
}

.complete,
.complete:hover,
.complete:active {
  background-color: $color-mountain-meadow !important;
  color: $color-white !important;
  border-color: transparent !important;

  :global(.ladda-label) {
    background-image: url('../images/tick-complete.svg') !important;
    background-position: left center !important;
    background-repeat: no-repeat !important;
    padding-left: 25px;
    background-origin: border-box;
  }
}

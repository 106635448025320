@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

$link-size: 42px;

.update-link {
  border-radius: 50%;
  display: inline-block;
  width: $link-size;
  height: $link-size;
  flex: 0 0 $link-size;

  &.active {
    background-color: $color-brand-secondary;
  }

  &.inactive {
    background-color: $color-very-light-grey;
  }
}

.icon {
  margin: 8px auto;
  width: 20px;
  height: 20px;
}

// Social button skin
.social-button-skin(
		@padding-left: 50px;
		@background-color;
		@background-color-hover;
		@background-color-active;
		@background-color-disabled;
		@color;
		@icon-top;
		@icon-left;) {
	padding-left: @padding-left;
	padding-right: @padding-left;
	background: @background-color;
	color: @color;
	position: relative;

	&:before {
		content:'';
		display: inline-block;
		position: absolute;
		top: @icon-top;
		left: @icon-left;

		.js-is-loading& {
			display: none;
		}
	}
	.no-touchevents &:hover,
	.no-touch &:hover {
		background: @background-color-hover;
	}
	&:active {
		background: @background-color-active!important;
	}
    &.is-disabled,
	.no-touchevents &.is-disabled:hover,
	.no-touch &.is-disabled:hover {
		background: @background-color-disabled;
		color: @color;
	}
	// Integration with dna-button-group
	:not(.is-active) &:hover {
		color: @color;
	}
}

.social-button-skin-large(@icon-top;) {
	@media @mq-l{
		&:before {
			top: @icon-top;
		}
	}
}

.social-button-skin-collapse(@icon-left; @icon-left-bp-l) {
	.dna-button-collapse&:before {
		left: @icon-left;

		@media @mq-l{
			left: @icon-left-bp-l;
		}
	}
}

.social-button-skin-toggle(@icon-left;
	@icon-top;
	@background-color;
	@background-color-active;) {
	&.dna-button-toggle {
		background: @background-color;

		&.is-active {
			background: @background-color-active;
		}
		&.is-not-authorized {
			background: @white;
			box-shadow: inset 0 0 0 1px rgba(red(@background-color-active),green(@background-color-active),blue(@background-color-active), 1);
		}
		&:before {
			left: @icon-left;
			top: @icon-top;
			display: none;
		}
		&:hover {
			background: @background-color;
		}
	}
}

.social-button-counter(@color) {
	color: @color;
}

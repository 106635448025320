.container {
  order: 2;
  margin-bottom: 20px;
}

.supporters-container {
  display: flex;
  justify-content: center;
}

.supporter-avatar-container {
  overflow: hidden;
  display: flex;
  max-width: 250px;
  margin: 0 2px;
}

.supporter-avatar {
  width: 100%;
}

//++++++++++++++++++++++++++++++++
//+ Button layout decorators
//++++++++++++++++++++++++++++++++
@btn-layout-min-width: 140px;
@btn-layout-max-width: 300px;

.dna-button-inline {
	display:inline-block;
	min-width: @btn-layout-min-width;
	max-width: @btn-layout-max-width;
	width:auto;
}

.dna-button-pull {
	float:left;
	min-width: @btn-layout-min-width;
	max-width: @btn-layout-max-width;
}

.dna-button-push {
	float:right;
	min-width: @btn-layout-min-width;
	max-width: @btn-layout-max-width;
}
@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

:global {
  .jg-page-owner-navbar {
    display: flex;
    flex-direction: row;
  }

  @include large {
    .jg-page-owner-navbar {
      flex-flow: row nowrap;
      justify-content: space-between;
    }

    .jg-page-owner-navbar .jg-btn {
      max-width: $global-spacing-unit * 8;
    }

    .jg-show\@lg {
      display: initial !important;
    }
  }
}

.fixed {
  position: static;

  @include large {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
  }
}

.back-button {
  flex: 50% 1 1;
}

.mobile-edit-button {
  margin-right: 0;

  @include large{
    margin-right: 18px;
  }
}

.mobile-bar {
  flex: 50% 1 1;
  margin-left: 12px;
  font-weight: 400;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  @include large {
    margin-left: 0px;
    font-weight: 300;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

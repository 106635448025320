@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.grey-cross {
  background-image: url('../images/MOB-close-icon-grey-active.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 13px;
  height: 13px;
  right: 16px;
  top: 16px;
  position: absolute;
  cursor: pointer;
  border: none;
  background-color: transparent;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background-image: url('../images/MOB-close-icon-grey-hover.svg');
  }

  @include large {
    background-image: url('../images/DT-close-icon-grey-active.svg');
    width: 20px;
    height: 20px;
    right: 20px;
    top: 20px;

    &:hover {
      background-image: url('../images/DT-close-icon-grey-hover.svg');
    }
  }
}

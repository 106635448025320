@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  border-top: 1px solid $color-very-light-grey;
  color: $color-nero;
  font-family: $global-font-family;
  font-size: 1rem;
  padding: 16px;
  display: none;

  @include large {
    border: 0;
    padding: 32px;
    display: block;
  }
}

.title {
  display: block;
  text-align: center;
  margin-bottom: 16px;

  @include large {
    margin-bottom: 24px;
  }
}

.mobile-only {
  @include large {
    display: none;
  }
}

.mobile-button-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.desktop-button-container {
  display: none;
  width: 100%;
  justify-content: center;

  @include large {
    display: flex;
  }
}

.social-button-wrapper {
  flex: 1;
  margin: 0 10px;
  max-width: 200px;
}

.button {
  border-radius: 50%;
  line-height: 0;
  font-size: 0;
  color: transparent;
  cursor: pointer;
  margin: 6px;
  border: 0;
}

.social-button {
  @extend .button;
  padding: 25px;
}

.button-no-padding {
  @extend .button;
}

.button-facebook {
  @extend .social-button;
  background-image: url('../SocialShare/images/share-facebook.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #3e5193;
}

.button-twitter {
  @extend .social-button;
  background-image: url('../SocialShare/images/share-twitter.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #588ecb;
}

.button-email {
  @extend .social-button;
  background-image: url('../SocialShare/images/share-email.svg');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: 50% 48%;
  background-color: #989898;
  margin: 6px;
}

.whatsapp-styles {
  background-image: url('../SocialShare/images/share-whatsapp.svg');
  background-repeat: no-repeat;
  background-color: #3faa35;
}

.button-whatsapp {
  display: block;
  @extend .social-button;
  @extend .whatsapp-styles;
  background-size: 85%;
  background-position: 57% 40%;
}

.sms-styles {
  background-image: url('../SocialShare/images/share-sms.svg');
  background-repeat: no-repeat;
  background-size: 85%;
  background-position: 57% 40%;
  background-color: #2965ae;
}

.button-sms {
  display: block;
  @extend .social-button;
  @extend .sms-styles;
}

.button-sms-block {
  @extend .button-no-padding;
  @extend .sms-styles;
  background-size: initial;
  background-position: 10px;
  width: 100%;
  border-radius: 3px;
  font-size: 18px;
  color: $color-white;
  margin: 0;

  &:focus,
  &:hover {
    @extend .sms-styles;
    background-size: initial;
    background-position: 10px;
    background-color: darken(#2965ae, 15%);
  }
}

.button-messenger {
  @extend .button-no-padding;
  background-image: url('../SocialShare/images/share-messenger.svg');
  background-repeat: no-repeat;
  background-position: 5% 50%;
  display: block;
  background-color: #007fff;
  border-radius: 3px;
  font-size: 16px;
  color: $color-white;

  &:focus,
  &:hover {
    background-color: darken(#007fff, 15%);
    background-image: url('../SocialShare/images/share-messenger.svg');
    background-repeat: no-repeat;
    background-position: 5% 50%;
  }
}

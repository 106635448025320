@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';

.container {
  align-items: center;
  background-color: #eef3f9;
  border-top: 1px solid $color-very-light-grey;
  padding: 20px;
  text-align: center;
  width: 100%;
}

.facebook-container {
  align-self: center;
  display: block;
  margin: 0 auto;
  width: 90%;

  @include large {
    flex: auto;
    width: 45%;
  }
}

@import '../../..//dna-core/components/global/global';
@import '../../..//dna-core/components/variables/components/form';
@import '../input/input';

@textarea-height:187px;

//++++++++++++++++++++++++++++++++
//+ DNA textarea
//++++++++++++++++++++++++++++++++

.dna-textarea {
	&:extend(.dna-input all);
	position:relative;
	resize:none;
	line-height:1.2;
	height:@textarea-height;
}

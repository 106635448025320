@import '~@justgiving/styles/src/settings';
@import '~@justgiving/styles/src/tools';

.disabled-trackable-button {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  text-align: center;
  height: $global-spacing-unit-lg;
  color: $color-white;
  background-color: $color-very-light-grey;
  padding: $global-spacing-unit-sm;
  border-radius: $global-radius;
  line-height: $global-spacing-unit-md;
  font-size: 16px;
  cursor: default;

  @include large {
    font-size: 18px;
  }
}

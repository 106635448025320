@import '~@justgiving/styles/src/_settings';
@import '~@justgiving/styles/src/_tools';
@import './SocialShareButton.scss';

.copy {
  @include large {
    margin: 20px 16px;
    width: auto;
    padding: 10px;
    overflow: hidden;
    background-image: none;
    border: 1px solid #999;
    border-radius: 5px;
  }

  button {
    @extend .share-button;

    color: #000;
    background: none;
    font-size: 16px;
    padding-top: 10px;

    @include large {
      display: inline-block;
      color: #1d67be;
      float: right;
      width: 95px;
      padding-top: 4px;
      border: none;
    }
  }

  svg {
    width: 18px;
    height: 18px;
    margin: 20px 0 0 15px;

    @include large {
      width: 24px;
      height: 24px;
      margin: 0;
      padding: 0 3px;
    }
  }
}

.url {
  display: none;
  vertical-align: middle;
  max-width: 412px;
  padding-left: 35px;
  font-size: 14px;
  font-weight: 400;
  color: #999;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include large {
    display: inline-block;
  }
}
